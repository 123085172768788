import { useEffect } from "react";
import {
  IonItem,
  IonLabel,
  IonGrid,
} from "@ionic/react";
import "react-phone-number-input/style.css";
import React from "react";


interface IProps {
  isUpdate?: boolean;
  chat: any[];

}

const CreateTicket: React.FC<IProps> = (props) => {
  const { chat, isUpdate = false } = props;

  useEffect(() => {
    scrollToBottom();
  }, [chat]);

  const scrollToBottom = () => {
    const goToBottom = document.getElementById("go-to-bottom");
    if (goToBottom?.scrollIntoView)
      goToBottom.scrollIntoView({ behavior: "smooth" });
  };

  const isHTML = (str: any) => {
    const doc = new DOMParser().parseFromString(str, "text/html");
    return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
  };


  return (
    <div>
      {!isUpdate && (
        <form>
          <IonGrid>
            {chat.map((element, index) => (
              <IonItem
                id={index === chat.length - 1 ? "go-to-bottom" : ""}
                lines="none"
                key={index}
                style={{
                  marginLeft: element.sender === "Agent" ? "8%" : "",
                  marginRight: element.sender === "Agent" ? "" : "8%",
                }}
              >
                <IonLabel
                  style={{
                    backgroundColor:
                      element.sender === "Agent" ? "#DCF8C6" : "#E5E5EA",
                    borderRadius: "10px",
                    padding: "10px",
                    alignSelf:
                      element.sender === "Agent" ? "flex-end" : "flex-start",
                    height: "auto",
                    display: "flex",
                    flexDirection: "column",
                    color: "#666",
                  }}
                >
                  {isHTML(element?.body) ? (
                    <div dangerouslySetInnerHTML={{ __html: element?.body }} style={{
                      whiteSpace: 'normal',
                    }} />
                  ) : (
                    element?.body
                  )}
                  <br />
                  <div
                    style={{
                      fontSize: "0.8em",
                      color: "#666",
                      marginTop: "6px",
                      alignSelf:
                        element.sender === "Agent" ? "flex-end" : "flex-start",
                    }}
                  >
                    {`${new Intl.DateTimeFormat("en-US", {
                      month: "short",
                      day: "2-digit",
                      year: "numeric",
                      hour: "numeric",
                      minute: "2-digit",
                      hour12: true,
                    }).format(new Date(element?.created_at))}`}
                  </div>
                </IonLabel>
              </IonItem>
            ))}
          </IonGrid>
        </form>
      )}

    </div>
  );
};

export default CreateTicket;
