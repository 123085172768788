import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { IonAlert, IonButton, IonChip, IonCol, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonList, IonToggle, IonModal, IonRow, IonToast, IonSelect, IonSelectOption } from '@ionic/react';
import './CreateFarm.css';
import { getFarm, registerFarm, updateFarm, postpubliclyAccessible, getFarmUserIdsPaginated, searchFarm } from '../services/farm.service';
import { handleErrors, Regex, toInputLowercase } from '../services/common.service';
import { GeolocationService, getReverseGecodingAddress, getElevation } from '../services/geolocation.service';
import { useLocation } from 'react-router-dom';
import SelectItem from './SelectItem';
import useNavigation from '../hooks/useNavigation';
import { addCircle, closeCircle, reload } from 'ionicons/icons';
import LoadingSkeleton from './LoadingSkeleton';
import { generateUniqueId } from '../hooks/common';
import UserList from '../pages/UserList';


const CreateFarm = (props: any) => {
    const [loading, setLoading] = useState(false);
    const navigateTo = useNavigation();
    const location = useLocation();
    const [farm, setFarm] = useState();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [prefilled, setPrefilled] = useState(false);
    const [onSuccess, setOnSuccess] = useState('');
    const [onError, setOnError] = useState('');
    const [showPubliclyAccessibleModal, setshowPubliclyAccessibleModal] = useState(false);
    const farmUserId = (location.state as any)?.farmUserId ?? '';
    const farmId: any = (location.state as any)?.farmId ?? null;

    function selectpubliclyAccessible(e: CustomEvent) {
        if (!e.detail.checked) {
            formik.setFieldValue('publiclyAccessible', false);
        }
        else {
            formik.setFieldValue('publiclyAccessible', true);
        }
        setshowPubliclyAccessibleModal(true);
    }

    async function savepubliclyAccessible(val: boolean){
        try{
            if (farm) {
               const response = await postpubliclyAccessible(formik.values.farmId, val).then(handleErrors) ;
                 setOnSuccess('Updated');
            }
        }catch(err: any) {
            setOnError(err);
        }
    };

    function setFarmValues(currentFarm: any) {
        if (currentFarm) {
            formik.setValues({
                edit: true,
                farmUserId: currentFarm.farmUserId,
                farmId: currentFarm.farmId,
                name: currentFarm.name,
                place: currentFarm.place,
                taluka: currentFarm.taluka,
                district: currentFarm.district,
                area: currentFarm.area,
                altitude: currentFarm.altitude,
                location: {
                    lat: currentFarm.location?.lat,
                    lng: currentFarm.location?.lng
                },
                publiclyAccessible: !!currentFarm.publiclyAccessible,
                agronomistId: currentFarm.agronomistId ? [...currentFarm.agronomistId] : [],
                farmManagerId: currentFarm.farmManagerId ? [...currentFarm.farmManagerId] : [],
                dealerId: currentFarm.dealerId || ""
            });
        }
        setFarm(currentFarm);
    }

    function setInitialLatLng() {
        const geoService = new GeolocationService();
        geoService.getCurrentPosition()
            .then(geolocation => {
                formik.setFieldValue('location.lat', geolocation!.coords.latitude.toFixed(6) ?? '');
                formik.setFieldValue('location.lng', geolocation!.coords.longitude.toFixed(6) ?? '');
                formik.setFieldValue('altitude', geolocation!.coords.altitude ?? '');
                if (geolocation?.coords.latitude && geolocation?.coords.longitude) {
                    getReverseGecodingAddress(geolocation.coords.latitude, geolocation.coords.longitude)
                        .then(resp => {
                            formik.setFieldValue('place', resp?.address?.village || resp?.address?.neighbourhood || resp?.address?.suburb || '');
                            formik.setFieldValue('taluka', resp?.address?.county || '');
                            formik.setFieldValue('district', resp?.address?.state_district || '');
                        });
                    if (!geolocation?.coords?.altitude) {
                        getElevation(geolocation.coords.latitude, geolocation.coords.longitude)
                            .then(resp => {
                                formik.setFieldValue('altitude', resp?.results ? resp.results[0]?.elevation || '' : '');
                            });
                    }
                }
            });
    }
    useEffect(() => {
        let mounted = true;
        setLoading(true);
        if (farmId) {
            getFarm(farmId)
                .then(farm => {
                    if (mounted) {
                        setFarmValues(farm);
                        setPrefilled(true);
                        setLoading(false);
                    }
                });
        } else if (farmUserId) {
            formik.setFieldValue('farmUserId', (location.state as any)?.farmUserId ?? '');
            setPrefilled(true);
            setInitialLatLng();
            setLoading(false);
        } else {
            setInitialLatLng();
            setLoading(false);
        }
        return () => { mounted = false };
    }, [farmId]);

    const [showSelectUserModal, setShowSelectUserModal] = useState(false);

    function setFarmUserId(item: any) {
        formik.setFieldValue('farmUserId', item?.farmUserId);
        setShowSelectUserModal(false);
    }

    const [showSelectAgronomistModal, setShowSelectAgronomistModal] = useState(false);

    function addAgronomist(item: any) {
        formik.values.agronomistId.push(item.farmUserId);
        setShowSelectAgronomistModal(false);
    }

    function removeAgronomist(itemIndex: number) {
        formik.values.agronomistId.splice(itemIndex, 1);
        formik.setFieldValue('agronomistId', [...formik.values.agronomistId]);
    }

    const [showSelectFarmManagerModal, setShowSelectFarmManagerModal] = useState(false);

    function addFarmManager(item: any) {
        formik.values.farmManagerId.push(item.farmUserId);
        setShowSelectFarmManagerModal(false);
    }

    function removeFarmManager(itemIndex: number) {
        formik.values.farmManagerId.splice(itemIndex, 1);
        formik.setFieldValue('farmManagerId', [...formik.values.farmManagerId]);
    }

    const formik = useFormik({
        initialValues: {
            edit: false,
            farmUserId: '',
            farmId: '',
            name: '',
            place: '',
            taluka: '',
            district: '',
            area: '',
            altitude: '',
            location: {
                lat: '',
                lng: ''
            },
            publiclyAccessible: false,
            agronomistId: [] as string[],
            farmManagerId: [] as string[],
            dealerId: ""
        },
        validationSchema: Yup.object({
            farmUserId: Yup.string().trim().required('Required'),
            farmId: Yup.string().when('edit', {
                is: (val: boolean) => !val,
                then: Yup.string().trim().required('Required').matches(Regex.id, 'Id can only contain lowercase chars and numbers'),
            }),
            name: Yup.string().trim().required('Required'),
            place: Yup.string().trim().required('Required'),
            area: Yup.string().trim().required('Required'),
            altitude: Yup.string().trim().required('Required'),
            publiclyAccessible: Yup.boolean(),
            location: Yup.object({
                lat: Yup.string().trim()
                    .required('Required').matches(Regex.latitude, 'Invalid latitude'),
                lng: Yup.string().trim()
                    .required('Required').matches(Regex.longitude, 'Invalid longitude'),
            })
        }),
        onSubmit: values => {
            setShowConfirmation(true);
        },
    });

    const saveFarm = async (values: any) => {
        try {
            delete values.edit;
            if (!farm) {
                const response = await registerFarm(values).then(handleErrors);
                setOnSuccess(response.message);
                navigateTo('/tabs/plot', {
                    farmUserId: values.farmUserId,
                    farmId: response.farmId
                });
            } else {
                const updatedObj = Object.assign({}, farm, values);
                const response = await updateFarm(updatedObj).then(handleErrors);
                setOnSuccess('Farm details updated');
                setFarmValues(response);
            }
        } catch (err: any) {
            setOnError(err);
        }
    };


    function generateId(){
        if(!formik.values.edit){
            generateUniqueId(formik.values.name, searchFarm, (id: string) => formik.setFieldValue('farmId', id))
        }
    }

    return (
        <>
            {loading && <LoadingSkeleton />}
            {!loading &&
                <form onSubmit={formik.handleSubmit}>
                    <IonList>
                        <IonItem>
                            <IonLabel position="stacked">User Id</IonLabel>
                            <IonInput readonly disabled={prefilled} id="farmUserId" name="farmUserId" value={formik.values.farmUserId} placeholder="Select User Id" onClick={e => setShowSelectUserModal(true)}></IonInput>
                        </IonItem>
                        {formik.touched.farmUserId && formik.errors.farmUserId ? (<div className="errorMsg">{formik.errors.farmUserId}</div>) : null}

                        <IonItem>
                            <IonLabel position="stacked">Farm Name</IonLabel>
                            <IonInput id="name" name="name" onBlur={() => generateId()} value={formik.values.name} placeholder="Farm Name" onIonChange={formik.handleChange}></IonInput>
                        </IonItem>
                        {formik.touched.name && formik.errors.name ? (<div className="errorMsg">{formik.errors.name}</div>) : null}

                        <IonItem>
                            <IonLabel position="stacked">Farm Id</IonLabel>
                            <IonInput id="farmId" disabled={formik.values.farmId!=null} name="farmId"  value={formik.values.farmId} placeholder="Farm Id" onIonInput={toInputLowercase} onIonChange={formik.handleChange}></IonInput>
                        </IonItem>
                        {formik.touched.farmId && formik.errors.farmId ? (<div className="errorMsg">{formik.errors.farmId}</div>) : null}

                       

                        <IonItem>
                            <IonLabel position="stacked">Place</IonLabel>
                            <IonInput id="place" name="place" value={formik.values.place} placeholder="Place" onIonChange={formik.handleChange}></IonInput>
                        </IonItem>
                        {formik.touched.place && formik.errors.place ? (<div className="errorMsg">{formik.errors.place}</div>) : null}

                        <IonItem>
                            <IonLabel position="stacked">Taluka</IonLabel>
                            <IonInput id="taluka" name="taluka" value={formik.values.taluka} placeholder="Taluka" onIonChange={formik.handleChange}></IonInput>
                        </IonItem>
                        {formik.touched.taluka && formik.errors.taluka ? (<div className="errorMsg">{formik.errors.taluka}</div>) : null}

                        <IonItem>
                            <IonLabel position="stacked">District</IonLabel>
                            <IonInput id="district" name="district" value={formik.values.district} placeholder="District" onIonChange={formik.handleChange}></IonInput>
                        </IonItem>
                        {formik.touched.district && formik.errors.district ? (<div className="errorMsg">{formik.errors.district}</div>) : null}

                        <IonItem>
                            <IonLabel position="stacked">Latitude</IonLabel>
                            <IonIcon icon={reload} slot="end" onClick={e => setInitialLatLng()} />
                            <IonInput id="location.lat" name="location.lat" inputmode="decimal" value={formik.values.location.lat} placeholder="Latitude" onIonChange={formik.handleChange}></IonInput>
                        </IonItem>
                        {formik.touched.location?.lat && formik.errors.location?.lat ? (<div className="errorMsg">{formik.errors.location.lat}</div>) : null}

                        <IonItem>
                            <IonLabel position="stacked">Longitude</IonLabel>
                            <IonInput id="location.lng" name="location.lng" inputmode="decimal" value={formik.values.location.lng} placeholder="Longitude" onIonChange={formik.handleChange}></IonInput>
                        </IonItem>
                        {formik.touched.location?.lng && formik.errors.location?.lng ? (<div className="errorMsg">{formik.errors.location.lng}</div>) : null}

                        <IonItem>
                            <IonLabel position="stacked">Altitude</IonLabel>
                            <IonInput id="altitude" name="altitude" inputmode="numeric" value={formik.values.altitude} placeholder="Altitude" onIonChange={formik.handleChange}></IonInput>
                        </IonItem>
                        {formik.touched.altitude && formik.errors.altitude ? (<div className="errorMsg">{formik.errors.altitude}</div>) : null}

                        <IonItem>
                            <IonLabel position="stacked">Area</IonLabel>
                            <IonInput id="area" name="area" inputmode="numeric" value={formik.values.area} placeholder="Area (In Acres)" onIonChange={formik.handleChange}></IonInput>
                        </IonItem>
                        {formik.touched.area && formik.errors.area ? (<div className="errorMsg">{formik.errors.area}</div>) : null}
                        <IonItem>
                            <IonLabel position="stacked">Partner</IonLabel>
                            <IonSelect id="dealerId" name="dealerId" placeholder="Select Partner" onIonChange={formik.handleChange} value={formik.values.dealerId}>
                                <IonSelectOption value="fyllo">Fyllo</IonSelectOption>
                                <IonSelectOption value="orchardly">Orchardly</IonSelectOption>
                            </IonSelect>
                        </IonItem>
                        {farm && 
                        <>
                        <IonItem>
                            <IonLabel>Publicly Accessible</IonLabel>
                            <IonToggle id="publiclyAccessible" name="publiclyAccessiblee" checked={formik.values.publiclyAccessible} onIonChange={selectpubliclyAccessible} />
                        </IonItem>
                        {formik.touched.publiclyAccessible && formik.errors.publiclyAccessible ? (<div className="errorMsg">{formik.errors.publiclyAccessible}</div>) : null}
                        </>}
                        <IonItem lines='none'>
                            <IonLabel>Agronomists</IonLabel>
                            <IonIcon icon={addCircle} slot="end" onClick={e => setShowSelectAgronomistModal(true)} />
                        </IonItem>
                        <IonItem>
                            <IonGrid>
                                <IonRow> 
                                    {formik.values.agronomistId.length > 0 && formik.values.agronomistId.map((item, index) =>
                                        <IonCol key={index}>
                                            <IonChip outline={true} color="primary">
                                                <IonLabel>{item}</IonLabel>
                                                <IonIcon icon={closeCircle} onClick={e => removeAgronomist(index)} />
                                            </IonChip>
                                        </IonCol>
                                    )}
                                    {formik.values.agronomistId.length === 0 &&
                                        <IonCol><IonLabel class="secondary-text">No Agronomist assigned</IonLabel></IonCol>}
                                </IonRow>
                            </IonGrid>
                        </IonItem>

                        <IonItem lines='none'>
                            <IonLabel>Farm Managers</IonLabel>
                            <IonIcon icon={addCircle} slot="end" onClick={e => setShowSelectFarmManagerModal(true)} />
                        </IonItem>
                        <IonItem>
                            <IonGrid>
                                <IonRow>
                                    {formik.values.farmManagerId.length > 0 && formik.values.farmManagerId.map((item, index) =>
                                        <IonCol key={index}>
                                            <IonChip outline={true} color="primary">
                                                <IonLabel>{item}</IonLabel>
                                                <IonIcon icon={closeCircle} onClick={e => removeFarmManager(index)} />
                                            </IonChip>
                                        </IonCol>
                                    )}
                                    {formik.values.farmManagerId.length === 0 &&
                                        <IonCol><IonLabel class="secondary-text">No Farm Manager assigned</IonLabel></IonCol>}
                                </IonRow>
                            </IonGrid>
                        </IonItem>
                    </IonList>

                    <IonButton expand="block" type="submit">{(!farm ? 'Create' : 'Update') + ' Farm'}</IonButton>
                </form>
            }

            {showSelectUserModal && <IonModal isOpen={showSelectUserModal} >
                <UserList
                    selectedValue={formik.values.farmUserId}
                    onClickHandler={setFarmUserId}
                    onClose={setShowSelectUserModal}
                />
            </IonModal>}

            {showSelectAgronomistModal && <IonModal isOpen={showSelectAgronomistModal} >
            <UserList
                    selectedValue=''
                    hiddenItems={formik.values.agronomistId}
                    onClickHandler={addAgronomist}
                    onClose={setShowSelectAgronomistModal}
                />
            </IonModal>}

            {showSelectFarmManagerModal && <IonModal isOpen={showSelectFarmManagerModal} >
            <UserList
                    selectedValue=''
                    hiddenItems={formik.values.farmManagerId}
                    onClickHandler={addFarmManager}
                    onClose={setShowSelectFarmManagerModal}
                />
            </IonModal>}

            <IonAlert
                isOpen={showConfirmation}
                onDidDismiss={() => setShowConfirmation(false)}
                header={'Confirm!'}
                message={'Please make sure that you have verified all the farm details. Save farm?'}
                buttons={[
                    {
                        text: 'No',
                        role: 'cancel',
                        cssClass: 'secondary'
                    },
                    {
                        text: 'Yes',
                        handler: () => {
                            saveFarm(formik.values);
                        }
                    }
                ]}
            />

            <IonAlert
                isOpen={showPubliclyAccessibleModal}
                onDidDismiss={() => setshowPubliclyAccessibleModal(false)}
                header={'Confirm!'}
                message={'Are you sure you want to update this data?'}
                buttons={[
                    {
                        text: 'No',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            formik.values.publiclyAccessible = !formik.values.publiclyAccessible;
                            setshowPubliclyAccessibleModal(false);
                        }
                    },
                    {
                        text: 'Yes',
                        handler: () => {
                            savepubliclyAccessible(formik.values.publiclyAccessible)
                        }
                    }
                ]}
            />

            <IonToast
                isOpen={!!onSuccess}
                onDidDismiss={() => setOnSuccess('')}
                message={onSuccess}
                duration={2000}
                color="success"
            />

            <IonToast
                isOpen={!!onError}
                onDidDismiss={() => setOnError('')}
                message={onError}
                duration={2000}
                color="danger"
            />
        </>
    );
};

export default CreateFarm;