import { IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonIcon, IonLabel } from '@ionic/react';
import { Redirect, Route } from 'react-router-dom';
import { cashOutline, gridOutline, homeOutline, personOutline, squareOutline } from 'ionicons/icons';
import User from '../pages/User';
import UserList from '../pages/UserList';
import Farm from '../pages/Farm';
import FarmList from '../pages/FarmList';
import Plot from '../pages/Plot';
import PlotList from '../pages/PlotList';
import Voucher from '../pages/Voucher';
import Home from './Home';
import MaintenanceList from './MaintenanceList';
import Maintenance from './Maintenance';
import LeadList from './LeadList';
import Lead from './Lead';
import ReferralList from './ReferralList';
import Referral from './Referral';
import FaultyPlots from './FaultyPlots';
import FormulaList from './FormulaList';
import Formula from './Formula';
import InteractionList from './InteractionList';
import Interaction from './Interaction';
import TestDevice from './TestDevice';
import DeviceGraphs from '../components/DeviceGraphs';
import MeetingList from './MeetingList';
import Meeting from './Meeting';
import SalesPlan from './SalesPlan';
import SalesPlanList from './SalesPlanList';
import SalesActivity from './SalesActivity';
import Ticket from './Ticket';
import TicketList from './TicketList';
import OrderList from './OrderList';
import Order from './Order';
import FarmerProfileList from './FarmerProfileList';
import FarmerProfile from './FarmerProfile';
import EventFarmerForm from '../components/EventFarmerForm';
const MainTabs: React.FC = () => {
    return (
        <IonTabs>
            <IonRouterOutlet>
                <Route exact path="/tabs/home">
                    <Home />
                </Route>
                <Route exact path="/tabs/users">
                    <UserList />
                </Route>
                <Route exact path="/tabs/user">
                    <User />
                </Route>
                <Route exact path="/tabs/farms">
                    <FarmList />
                </Route>
                <Route exact path="/tabs/farm">
                    <Farm />
                </Route>
                <Route exact path="/tabs/plots">
                    <PlotList readOnlyView={false}/>
                </Route>
                <Route exact path="/tabs/plot">
                    <Plot />
                </Route>
                <Route exact path="/tabs/voucher">
                    <Voucher />
                </Route>
                <Route exact path="/tabs/maintenances">
                    <MaintenanceList />
                </Route>
                <Route exact path="/tabs/maintenance">
                    <Maintenance />
                </Route>
                <Route exact path="/tabs/interactions">
                    <InteractionList />
                </Route>
                <Route exact path="/tabs/interaction">
                    <Interaction />
                </Route>
                <Route exact path="/tabs/leads">
                    <LeadList />
                </Route>
                <Route exact path="/tabs/lead">
                    <Lead />
                </Route>
                <Route exact path="/tabs/ticket">
                    <Ticket />
                </Route>
                <Route exact path="/tabs/tickets">
                    <TicketList />
                </Route>
                <Route exact path="/tabs/salesPlans">
                    <SalesPlanList />
                </Route>
                <Route exact path="/tabs/salesPlan">
                    <SalesPlan />
                </Route>
                <Route exact path="/tabs/salesActivity">
                    <SalesActivity />
                </Route>
                <Route exact path="/tabs/meetings">
                    <MeetingList />
                </Route>
                <Route exact path="/tabs/meeting">
                    <Meeting />
                </Route>
                <Route exact path="/tabs/referrals">
                    <ReferralList />
                </Route>
                <Route exact path="/tabs/referral">
                    <Referral />
                </Route>
                <Route exact path="/tabs/faulty-plots">
                    <FaultyPlots />
                </Route>
                <Route exact path="/tabs/formulas">
                    <FormulaList />
                </Route>
                <Route exact path="/tabs/formula">
                    <Formula />
                </Route>
                <Route exact path="/tabs/farmer-profiles">
                    <FarmerProfileList />
                </Route>
                <Route exact path="/tabs/farmer-profile">
                    <FarmerProfile />
                </Route>
                <Route exact path="/tabs">
                    <Redirect to="/tabs/home" />
                </Route>
                <Route exact path="/tabs/test-devices">
                    <TestDevice />
                </Route>
                <Route exact path="/tabs/test-devices/graph">
                    <DeviceGraphs />
                </Route>
                <Route exact path="/tabs/event-farmer-form/">
                    <EventFarmerForm />
                </Route>
                <Route exact path="/tabs/orders">
                    <OrderList />
                </Route>
                <Route exact path="/tabs/order">
                    <Order />
                </Route>
            </IonRouterOutlet>
            <IonTabBar slot="bottom">
                <IonTabButton tab="home" href="/tabs/home">
                    <IonIcon icon={homeOutline} />
                    <IonLabel>Home</IonLabel>
                </IonTabButton>
                <IonTabButton tab="user" href="/tabs/users">
                    <IonIcon icon={personOutline} />
                    <IonLabel>User</IonLabel>
                </IonTabButton>
                <IonTabButton tab="farm" href="/tabs/farms">
                    <IonIcon icon={gridOutline} />
                    <IonLabel>Farm</IonLabel>
                </IonTabButton>
                <IonTabButton tab="plot" href="/tabs/plots">
                    <IonIcon icon={squareOutline} />
                    <IonLabel>Plot</IonLabel>
                </IonTabButton>
                <IonTabButton tab="voucher" href="/tabs/voucher">
                    <IonIcon icon={cashOutline} />
                    <IonLabel>Voucher</IonLabel>
                </IonTabButton>
            </IonTabBar>
        </IonTabs>
    );
};

export default MainTabs;
