import * as XLSX from "xlsx";

/**
 * Utility function to download an Excel sheet with given data and filename.
 * @param filename - The name of the Excel file to be downloaded (with .xlsx extension).
 * @param data - An array of objects where each key represents a column name, and each value is the cell content.
 * @param orderOfHeader - An optional object where keys are field names in the data, and values are the column headers.
 */
export const downloadExcelSheet = (
  filename: string,
  data: Array<Record<string, any>>,
  orderOfHeader?: Record<string, string>
): void => {
  try {
    if (!filename.endsWith(".xlsx")) {
      filename += ".xlsx";  
    }

    if (!Array.isArray(data) || data.length === 0) {
      console.error("Data must be a non-empty array of objects.");
      return;
    }

    let formattedData: Array<Record<string, any>> = [];
    let headers: Array<{ key: string; title: string }> = [];

    if (orderOfHeader) {
      headers = Object.entries(orderOfHeader).map(([key, title]) => ({
        key,
        title,
      }));

      formattedData = data.map((item) => {
        const formattedRow: Record<string, any> = {};
        headers.forEach(({ key }) => {
          formattedRow[key] = item[key] ?? "";  
        });
        return formattedRow;
      });
    } else {
      headers = Object.keys(data[0]).map((key) => ({ key, title: key }));
      formattedData = data;
    }

    const worksheetData = [
      headers.map((header) => header.title),  
      ...formattedData.map((item) => headers.map(({ key }) => item[key])),  
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    headers.forEach((_, index) => {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: index });  
      if (!worksheet[cellAddress]) return;  
      worksheet[cellAddress].s = {
        fill: {
          fgColor: { rgb: "00FF00" },  
        },
        font: {
          color: { rgb: "FFFFFF" },  
          bold: true,  
        },
        alignment: {
          horizontal: "center",  
          vertical: "center",
        },
      };
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(workbook, filename);
  } catch (error) {
    console.error("Error while generating Excel file:", error);
  }
};
