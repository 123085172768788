import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import Loader from "../components/GlobalComponents/Loader";

interface LoaderContextType {
  isLoading: boolean;
  message: string;
  showLoader: (msg?: string) => void;
  hideLoader: () => void;
}

const LoaderContext = createContext<LoaderContextType | undefined>(undefined);

export const LoaderProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("Loading...");

  const isLoadingRef = useRef(isLoading);


  const showLoader = (msg: string = "Loading...") => {

    setMessage(msg);
    setIsLoading(true);

  // auto close in 15sec
    setTimeout(() => {
      if (isLoadingRef.current) {
        hideLoader();
      }
    }, 12000);

  };

  const hideLoader = () => {
    setIsLoading(false);
    setMessage("Loading...");
  };

  useEffect(() => {
    isLoadingRef.current = isLoading;
  }, [isLoading]);

  return (
    <LoaderContext.Provider
      value={{ isLoading, message, showLoader, hideLoader }}
    >
      <Loader/>
      {children}
    </LoaderContext.Provider>
  );
};

export const useLoader = () => {
  const context = useContext(LoaderContext);
  if (!context) {
    throw new Error("useLoader must be used within a LoaderProvider");
  }
  return context;
};
