import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonInput,
  IonContent,
  IonItem,
  IonLabel,
  IonPage,
  IonToast,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonTextarea,
  IonText,
} from "@ionic/react";
import { useEventStore } from "../store/useEventStore";
import { useLocation } from "react-router-dom";
import useNavigation from "../hooks/useNavigation";
import { useToast } from "../contexts/ToastContext";

interface Errors {
  name: boolean;
  mobile: boolean;
}
const validateFarmer = (farmer: { name: string; mobile: string }): Errors => {
  const errors: Errors = { name: false, mobile: false };

  const namePattern = /^[A-Za-z\s]+$/;
  if (!farmer.name || !namePattern.test(farmer.name)) {
    errors.name = true;
  }

  if (!farmer.mobile) {
    errors.mobile = true;
  } else {
    const mobilePattern = /^[0-9]{10}$/;
    if (!mobilePattern.test(farmer.mobile)) {
      errors.mobile = true;
    }
  }

  return errors;
};

const EventFarmerForm: React.FC = () => {
  const location = useLocation();
  const initialEventId = (location.state as any)?.eventId;
  const { addFarmerToEvent } = useEventStore.getState();

  const initialFarmerState = {
    name: "",
    mobile: "",
    village: "",
    crops: "",
    comments: "",
  };

  const [farmer, setFarmer] = useState(initialFarmerState);
  const [errors, setErrors] = useState<Errors>({
    name: false,
    mobile: false,
  });
  const { showToast } = useToast();

  const handleSaveFarmer = () => {
    const newErrors = validateFarmer(farmer);
    setErrors(newErrors);

    if (!Object.values(newErrors).includes(true)) {
      const farmerData = {
        ...farmer,
        id: Date.now(),
      };

      addFarmerToEvent(initialEventId, farmerData);
      showToast("This is a success message!", "success");
      setFarmer(initialFarmerState);
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle>Farmer Details</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent
        className="ion-padding"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <IonItem>
          <IonLabel position="stacked">
            Name <span style={{ color: "red" }}>*</span>
          </IonLabel>
          <IonInput
            placeholder="Enter farmer's name"
            value={farmer.name}
            onIonChange={(e) => setFarmer({ ...farmer, name: e.detail.value! })}
            required
            style={{
              border: errors.name ? "2px solid red" : "",
            }}
          />
          {errors.name && (
            <IonText color="danger">
              <p style={{ fontSize: "0.7rem", marginTop: "0px" }}>
                Proper Name is required!
              </p>
            </IonText>
          )}
        </IonItem>

        <IonItem>
          <IonLabel position="stacked">
            Mobile <span style={{ color: "red" }}>*</span>
          </IonLabel>
          <IonInput
            placeholder="Enter mobile number"
            value={farmer.mobile}
            onIonChange={(e) =>
              setFarmer({ ...farmer, mobile: e.detail.value! })
            }
            type="tel"
            required
            style={{
              border: errors.mobile ? "2px solid red" : "",
            }}
          />
          {errors.mobile && (
            <IonText color="danger">
              <p style={{ fontSize: "0.7rem", marginTop: "0px" }}>
                Please check the mobile number!
              </p>
            </IonText>
          )}
        </IonItem>

        <IonItem>
          <IonLabel position="stacked">
            Village <small>(Optional)</small>
          </IonLabel>
          <IonInput
            placeholder="Enter village"
            value={farmer.village}
            onIonChange={(e) =>
              setFarmer({ ...farmer, village: e.detail.value! })
            }
          />
        </IonItem>

        <IonItem>
          <IonLabel position="stacked">
            Crops <small>(Optional)</small>
          </IonLabel>
          <IonInput
            placeholder="Enter crops"
            value={farmer.crops}
            onIonChange={(e) =>
              setFarmer({ ...farmer, crops: e.detail.value! })
            }
          />
        </IonItem>

        <IonItem>
          <IonLabel position="stacked">
            Comments <small>(Optional)</small>
          </IonLabel>
          <IonTextarea
            placeholder="Enter comments"
            value={farmer.comments}
            onIonChange={(e) =>
              setFarmer({ ...farmer, comments: e.detail.value! })
            }
          />
        </IonItem>

        <div className="ion-margin-top">
          <IonButton expand="block" onClick={handleSaveFarmer}>
            Save
          </IonButton>
          <IonButton
            expand="block"
            color="light"
            onClick={() => setFarmer(initialFarmerState)}
          >
            Clear
          </IonButton>
        </div>

        {/* <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message="Farmer details saved successfully!"
          duration={1500}
          color="success"
        /> */}
      </IonContent>
    </IonPage>
  );
};

export default EventFarmerForm;
