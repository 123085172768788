import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonList, IonItem, IonLabel, IonGrid, IonRow, IonCol, IonInput, IonModal, IonPicker, IonToast } from '@ionic/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import './TransferToFarm.css';
import { getFarmIdsOfUser, getPlotIdsOfFarm } from '../services/farm.service';
import UserList from '../pages/UserList';
import { transferToFarm } from '../services/plot.service';

interface IProps {
    plotId: string;
    onClose: Function;
}

const FarmPicker = {
    name: "FarmId",
    options: [] as any[]
};

const setFarmPicker = (farmIds: any[]) => {
    FarmPicker.options = [];
    farmIds.forEach(item => {
        FarmPicker.options.push({
            text: item.farmId,
            value: item.farmId
        })
    });
};


const TransferToFarm: React.FC<IProps> = (props) => {
    const [onSuccess, setOnSuccess] = useState("");
    const [onError, setOnError] = useState("");

    const formik = useFormik({
        initialValues: {
            farmUserId: '',
            farmId: ''
        },
        validationSchema: Yup.object({
            farmUserId: Yup.string().trim().required('Required'),
            farmId: Yup.string().trim().required('Required')
        }),
        onSubmit: values => {
            transferToFarm(props.plotId, values.farmId).then(data => {
                setOnSuccess('Plot transferred successfully');
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            }).catch(err => {
                setOnError(err.message);
            });
        },
    });

    const [showSelectUserModal, setShowSelectUserModal] = useState(false);

    function setFarmUserId(item: any) {
        formik.setFieldValue('farmUserId', item.farmUserId);
        formik.setFieldValue('farmId', '');
        setShowSelectUserModal(false);
    }

    const [farmPickerIsOpen, setFarmPickerIsOpen] = useState(false);
    useEffect(() => {
        let mounted = true;
        if (formik.values.farmUserId) {
            getFarmIdsOfUser(formik.values.farmUserId)
                .then(items => {
                    if (mounted) {
                        setFarmPicker(items)
                    }
                })
        }
        return () => { mounted = false };
    }, [formik.values.farmUserId])

    function onClose() {
        props.onClose(false);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Transfer to Farm</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <form onSubmit={formik.handleSubmit}>
                    <IonList>
                        <IonItem>
                            <IonLabel position="stacked">User Id</IonLabel>
                            <IonInput readonly id="farmUserId" name="farmUserId" value={formik.values.farmUserId} placeholder="Select User Id" onClick={e => setShowSelectUserModal(true)}></IonInput>
                        </IonItem>
                        {formik.touched.farmUserId && formik.errors.farmUserId ? (<div className="errorMsg">{formik.errors.farmUserId}</div>) : null}

                        <IonItem>
                            <IonLabel position="stacked">Farm Id</IonLabel>
                            <IonInput readonly disabled={!formik.values.farmUserId} id="farmId" name="farmId" value={formik.values.farmId} placeholder="Select Farm Id" onClick={e => setFarmPickerIsOpen(true)}></IonInput>
                        </IonItem>
                        {formik.touched.farmId && formik.errors.farmId ? (<div className="errorMsg">{formik.errors.farmId}</div>) : null}

                    </IonList>
                    <IonGrid>
                        <IonRow>
                            <IonCol><IonButton className="shiftRight" type="submit">Submit</IonButton></IonCol>
                            <IonCol><IonButton onClick={onClose}>Close</IonButton></IonCol>
                        </IonRow>
                    </IonGrid>

                </form>

                {showSelectUserModal && <IonModal isOpen={showSelectUserModal} >
                <UserList
                    selectedValue={formik.values.farmUserId}
                    onClickHandler={setFarmUserId}
                    onClose={setShowSelectUserModal}
                />
                </IonModal>}

                <IonPicker
                    isOpen={farmPickerIsOpen}
                    columns={[FarmPicker]}
                    buttons={[
                        {
                            text: "Cancel",
                            role: "cancel",
                            handler: value => {
                                setFarmPickerIsOpen(false);
                            }
                        },
                        {
                            text: "Confirm",
                            handler: value => {
                                formik.values.farmId = value.FarmId.value;
                                setFarmPickerIsOpen(false);
                            }
                        }
                    ]}
                ></IonPicker>

            </IonContent>

            <IonToast
                    isOpen={!!onSuccess}
                    onDidDismiss={() => setOnSuccess("")}
                    message={onSuccess}
                    duration={2000}
                    color="success"
                  />
            
                  <IonToast
                    isOpen={!!onError}
                    onDidDismiss={() => setOnError("")}
                    message={onError}
                    duration={2000}
                    color="danger"
                  />
        </IonPage>
    );
};

export default TransferToFarm;
