import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { get, set, del } from 'idb-keyval';

// Custom storage using `idb-keyval` with error handling
const indexedDBStorage = {
  getItem: async (name) => {
    try {
      // console.log(`Retrieving ${name} from IndexedDB`);
      const value = await get(name);
      return value ? JSON.stringify(value) : null;
    } catch (error) {
      // console.error(`Error retrieving ${name} from IndexedDB:`, error);
      return null;  
    }
  },
  setItem: async (name, value) => {
    try {
      // console.log(`Saving ${name} to IndexedDB`);
      await set(name, JSON.parse(value));
    } catch (error) {
      // console.error(`Error saving ${name} to IndexedDB:`, error);
    }
  },
  removeItem: async (name) => {
    try {
      // console.log(`Deleting ${name} from IndexedDB`);
      await del(name);
    } catch (error) {
      // console.error(`Error deleting ${name} from IndexedDB:`, error);
    }
  },
};

// Zustand store
const useStore = create(
  persist(
    (set, get) => ({
      apiData: {}, // Stores API data
      lastFetched: {}, // Stores timestamps of last fetches
       etags:{},
        _hasHydrated: false,

        fetchData: async (apiKey, url, cacheDuration, finalOptions = {}) => {
          const now = Date.now();
          const { lastFetched = {}, apiData = {}, etags = {} } = get();
          const keyToStore = apiKey;
          const storedETag = etags[keyToStore];
        
          // Check if cache exists and is still valid
          if (lastFetched[keyToStore]) {
            const timeSinceLastFetch = now - lastFetched[keyToStore];
        
            if (timeSinceLastFetch < cacheDuration) {
              // Cache is valid
              // console.log(`Using cached data for ${keyToStore}. Age: ${timeSinceLastFetch} ms`);
        
              if (storedETag) {
                console.log("storedEtg",storedETag);
                finalOptions.headers = {
                  ...finalOptions.headers,
                  'If-None-Match': storedETag,  
                };
              }
              return apiData[keyToStore];
            } else {
              // console.log(`Cache expired for ${keyToStore} (Age: ${timeSinceLastFetch} ms). Fetching new data.`);
            }
          }
        
          // Fetch new data if cache has expired or no cache found
          // console.log(`Fetching new data for ${keyToStore}`);

          try {
            if (storedETag) {
              console.log("storedEtg",storedETag);
              finalOptions.headers = {
                ...finalOptions.headers,
                'If-None-Match': storedETag,  
              };
            }

            const response = await fetch(url, finalOptions);
        
            // 304 aya toh shirf time update krna hai.
            if (response.status === 304) {
              // Data not modified, return cached data
              // console.log(`Data for ${keyToStore} not modified. Using cached data.`);
              set((state) => ({
                lastFetched: { ...state.lastFetched, [keyToStore]: now },
              }));
              return apiData[keyToStore];
            }
        
            if (!response.ok) {
              throw new Error(`API call failed with status ${response.status}`);
            }

            let responseETag = response.headers.get('ETag');
            
            const data = await response.json();
        
            // Update state with new data
            set((state) => ({
              apiData: { ...state.apiData, [keyToStore]: data },
              lastFetched: { ...state.lastFetched, [keyToStore]: now },
              etags: { ...state.etags, [keyToStore]: responseETag },
            }));
        
            return data;
          } catch (error) {
            throw new Error(`Unable to fetch data for ${apiKey}: ${error.message}`);
          }
        },
        
        
      

      // TODO:: Need to remove the concept of cleaning up of FarmUserData
      // Cleanup mechanism for stale data
      cleanup: () => {
        console.log("Cleaning up stale data...");
        set((state) => {
          const now = Date.now();
          const newApiData = {};
          const newLastFetched = {};

          Object.entries(state.lastFetched).forEach(([key, timestamp]) => {
            const oneDay = 24 * 60 * 60 * 1000;
            if (key === "FarmUserData" || now - timestamp < oneDay) {
              // Keep FarmUserData or recent data
              newApiData[key] = state.apiData[key];
              newLastFetched[key] = timestamp;
            } else {
              console.log(`Removing stale data for ${key}`);
            }
          });

          return { apiData: newApiData, lastFetched: newLastFetched };
        });
      },

      clearCache: async () => {
        console.log('Clearing all cache data on logout...');
        await indexedDBStorage.removeItem('fyllo-api-cache');
        set(() => ({
          apiData: {},
          lastFetched: {},
          etags:{}
        }));
      },

      
    }),
    {
      name: 'fyllo-api-cache', 
      storage: createJSONStorage(() => indexedDBStorage),
      onRehydrateStorage: () => (state) => {
        console.log("[DEBUG] Zustand rehydration started.");
        state._hasHydrated = false; 
      },
      onRehydrationFinished: () => (state) => {
        console.log("[DEBUG] Zustand rehydration finished.");
        state._hasHydrated = true;   
      },
    }
  )
);
export default useStore;