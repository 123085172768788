import React from "react";
import { IonLoading } from "@ionic/react";
import { useLoader } from "../../contexts/LoaderContext";

const Loader: React.FC = () => {
  const { isLoading ,message} = useLoader(); 

  return (
    <IonLoading
      isOpen={isLoading} 
      message={message}  
      spinner="crescent"  
      translucent={true}  
    />
  );
};

export default Loader;
