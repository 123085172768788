import React from "react";
import { IonToast, IonIcon } from "@ionic/react";

type ToastType = "success" | "error" | "info";

interface ToastProps {
  isOpen: boolean;
  message: string;
  type: ToastType;
  onDidDismiss: () => void;
}

const Toast: React.FC<ToastProps> = ({ isOpen, message, type, onDidDismiss }) => {

  const color = type === "success" ? "success" : type === "error" ? "danger" : "medium";

  return (
    <IonToast
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
      message={message}
      duration={3000}
      position="top"
      color={color}
    />
  );
};

export default Toast;
