import { getData, postData, deleteData } from "./common.service";


export const getScreenType = async (formId: string) => {
    const url = "/form/" + formId;
    return getData(url, {
        sendTokenInHeader: true,
        basePath: process.env.REACT_APP_CORE_API_BASEPATH
    }).then(data => data.json())
}

export const getScreenDataById = async (screenType: string, id: string) => {
    const url = `/${screenType}/${id}`
    return getData(url, {
        sendTokenInHeader: true,
        basePath: process.env.REACT_APP_CORE_API_BASEPATH
    }).then(data => data.json())
}

export const postScreenData = async (screenType: string, payload: any) => {
    const url = `/${screenType}`;
    return postData(url, {
        sendTokenInHeader: true,
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        body: payload,
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(data => data.json())
}

export async function patchScreenData(screenType: string, payload: any) {
    const { id } = payload;
    const url = `/${screenType}/${id}`;

    return postData(url, {
        sendTokenInHeader: true,
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        usePatchMethod: true,
        body: payload,
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(data => data)
}

export async function deleteScreenData(screenType: string, id: any) {
    const url = `/${screenType}/${id}`

    return deleteData(url, {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data)
}

export async function getScreenData(
    screenType: string,
    filters: any[] = []
) {

    const qs: any = {
        order: ["created_date DESC"],
    };
    if (filters.length) {
        qs.where = { and: filters }
    }

    return getData(`/${screenType}?filter=` + JSON.stringify(qs), {
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        sendTokenInHeader: true,
    }).then(data => data.json())
}

export const getLocale = async (filter?: any,isCache=false) => {
    let url = "/locales";

    if (filter) {
        url += `?filter=${JSON.stringify(filter)}`;
    }
    const cacheKey = isCache ? "Local_lang" : "";

    return getData(url, {
        sendTokenInHeader: true,
        basePath: process.env.REACT_APP_CORE_API_BASEPATH
    },
    cacheKey
).then(data => {
    if(isCache){ return data
    }else return data.json()
})
}

export async function getFarmerProfileById(id: string) {
    const url = `/farmer-profiles/${id}`;

    return getData(url, {
        sendTokenInHeader: true,
        basePath: process.env.REACT_APP_CORE_API_BASEPATH,
        usePatchMethod: true,
    }).then(data => data.json())
}