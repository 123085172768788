import useStore from "../store/useStore";

const originalFetch: any = fetch;
const fetchWithRetry = require('fetch-retry')(originalFetch, {  
    retries: 5,
    retryDelay: (attempt: number, error: any, response: any) => {
        return Math.pow(2, attempt) * 1000;
    }  
});

export const Regex = {
    id: /^[a-z0-9]+$/,
    mobile: /^([0-9]{9,10})$/,
    latitude: /^(\+|-)?(?:90(?:(?:\.0{1,10})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,10})?))$/,
    longitude: /^(\+|-)?(?:180(?:(?:\.0{1,10})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,10})?))$/
};

export interface APIOptions {
    basePath?: string,
    sendTokenInUrl?: boolean,
    sendTokenInHeader?: boolean,
    body?: any,
    headers?: any,
    usePutMethod?: boolean
    usePatchMethod?: boolean
}

export async function handleErrors(response: Response) {
    if (!response.ok) {
        const err = await response.json();
        throw Error(err.error.message);
    }
    return response.status === 200 ? response.json() : undefined;
}

function getAccessToken(): string {
    return localStorage.getItem('access_token') ?? '';
}

export function setAccessToken(access_token: string) {
    localStorage.setItem('access_token', access_token);
}

export function clearAccessToken() {
    localStorage.removeItem('access_token');
}
export function clearFarmUserData() {
    localStorage.removeItem('FarmUserData');
}
export async function clearIndexDB(){
    const { clearCache } = useStore.getState()
    await clearCache();
}

function addAccessToken(url: string): string {
    const qsSeperator = url.includes('?') ? '&' : '?';
    return url.concat(qsSeperator + 'access_token=' + getAccessToken());
}

const defaultCacheExpiryTime=7*86400*1000;

export async function getData(url = '', options: APIOptions = {},apiCache="", cacheExpireTime=defaultCacheExpiryTime,timeout=10000) {
    const controller = new AbortController();
    setTimeout(() => {controller.abort();}, timeout)
    const signal =controller.signal;
    
    const abortPromise = new Promise((reject) => {
        signal.addEventListener("abort", () => {
            reject(new Error("Request aborted"));
        });
    });

    const basePath = options.basePath || process.env.REACT_APP_API_BASEPATH;
    const tokenRequiredInUrl = !!options.sendTokenInUrl;
    if (tokenRequiredInUrl) {
        url = addAccessToken(url);
    }
    const finalUrl = `${basePath}${url}`;
    const finalOptions: any = { method: 'get' };
    if (options.headers) {
        finalOptions.headers = options.headers;
    }
    const tokenRequiredInHeader = !!options.sendTokenInHeader;
    if (tokenRequiredInHeader) {
        finalOptions.headers = finalOptions.headers || {};
        finalOptions.headers['Authorization'] = `Bearer ${getAccessToken()}`
    }
    finalOptions.signal = signal;

    if (!!apiCache) {
        const { fetchData } = useStore.getState();
      const responseIamgetting= await fetchData(apiCache, finalUrl, cacheExpireTime, finalOptions);
      return responseIamgetting; 
      } 
    return await Promise.race([abortPromise, fetchWithRetry(finalUrl, finalOptions)]) ;
    }


export async function postData(url = '', options: APIOptions = {}) {
    const basePath = options.basePath || process.env.REACT_APP_API_BASEPATH;
    const tokenRequiredInUrl = !!options.sendTokenInUrl;
    if (tokenRequiredInUrl) {
        url = addAccessToken(url);
    }
    const finalUrl = `${basePath}${url}`;
    const finalOptions: any = { method: options.usePutMethod ? 'put' : options.usePatchMethod ? 'PATCH' : 'post' };
    if (options.headers) {
        finalOptions.headers = options.headers;
    }
    const tokenRequiredInHeader = !!options.sendTokenInHeader;
    if (tokenRequiredInHeader) {
        finalOptions.headers = finalOptions.headers || {};
        finalOptions.headers['Authorization'] = `Bearer ${getAccessToken()}`
    }
    if (options.body) {
        finalOptions.body = finalOptions.headers['Content-Type'] === 'application/json'
            ? JSON.stringify(options.body)
            : options.body;
    }
    return fetchWithRetry(finalUrl, finalOptions);
}

export async function deleteData(url = '', options: APIOptions = {}) {
    const basePath = options.basePath || process.env.REACT_APP_API_BASEPATH;
    const tokenRequiredInUrl = !!options.sendTokenInUrl;
    if (tokenRequiredInUrl) {
        url = addAccessToken(url);
    }
    const finalUrl = `${basePath}${url}`;
    const finalOptions: any = { method: 'delete' };
    if (options.headers) {
        finalOptions.headers = options.headers;
    }
    const tokenRequiredInHeader = !!options.sendTokenInHeader;
    if (tokenRequiredInHeader) {
        finalOptions.headers = finalOptions.headers || {};
        finalOptions.headers['Authorization'] = `Bearer ${getAccessToken()}`
    }
    return fetchWithRetry(finalUrl, finalOptions);
}

export const toInputLowercase = (e: any) => {
    e.target.value = ('' + e.target.value).toLowerCase();
};