import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { useFormik } from "formik";
import { getPlot } from "../services/plot.service";
import {
	getNewCropId,
	getCropDiseases,
	getCropPests,
} from "../services/crop.service";
import {
	IonButtons,
	IonContent,
	IonHeader,
	IonIcon,
	IonItem,
	IonLabel,
	IonPage,
	IonSelect,
	IonSelectOption,
	IonTitle,
	IonToast,
	IonToolbar,
	IonModal,
	IonButton,
	IonGrid,
	IonRow,
	IonCol,
	IonDatetime,
	IonBackButton,
} from "@ionic/react";
import { closeOutline } from 'ionicons/icons';

import { getObservationType, getPreviousSessions, postObservationData, putObservationData } from "../services/observation.service";
import './DiseasePestObservation.css';
import ObservationReadingTemplate from "./ObservationReadingTemplate";
import useNavigation from "../hooks/useNavigation";
import { arrowBack, informationCircle } from "ionicons/icons";
import moment from "moment";
import { cloneDeep } from "lodash";

type Props = {};

interface ICropDetails {
	id: string;
	name: string;
	activeStage: string;
	startDate: string;
	varietyId?: string;
}

type ToastState = {
	message: string;
	isOpen: boolean;
}

type Option = { label: string | number, value: string | number | boolean };

const DiseasePestObservation: React.FC<Props> = (props) => {
	const location = useLocation();
	const navigateTo = useNavigation();

	const [plotId, setPlotId] = useState<string>();

	const [observationId, setObservationId] = useState<string>();
	const [prevObservations, setPrevObservations] = useState<any[]>([]);
	const [prevObservationOptions, setPrevObservationOptions] = useState<Option[]>();
	const [showPrevObservations, setShowPrevObservations] = useState<boolean>(false);

	const observationDateRef = useRef<any>(null);

	const [canSubmitReadings, setCanSubmitReadings] = useState<boolean>(false);

	// crop states
	const [cropDetails, setCropDetails] = useState<ICropDetails>();
	const [diseasePestIds, setDiseasePestIds] = useState<any[]>([]);
	const [observationTypes, setObservationTypes] = useState<any[]>();

	// toast states
	const [successToast, setSuccessToast] = useState<ToastState>();
	const [errorToast, setErrorToast] = useState<ToastState>();

	// description modal states
	const [description, setDescription] = useState<string>("");
	const [showDescription, setShowDescription] = useState<boolean>(false);

	// crop scale labes
	const [scale, setScale] = useState<number[][]>([[], [], []]);
	const scaleToLabel: { [key: number]: string } = { 0: "Nil", 1: "Low", 2: "Medium", 3: "High" };

	// formik state
	const defaultDiseasePestObservation = {
		diseasePestId: "",
		observationTypeId: "",
	}
	const defaultFormValues = {
		vegetationReading: [],
		cropId: "",
		observationDate: new Date().toString(),
		previousSpray: "",
		previousSprayDate: "",
		plotId,
		cropStage: "",
		seasonStartDate: "", // crop-stage-start date
		rating: -1,
		modelPrediction: null,
		modelRiskLevel: null
	}

	const formik = useFormik({
		initialValues: { ...defaultDiseasePestObservation, ...defaultFormValues },
		onSubmit: () => { },
	});

	useEffect(() => {
		const plotId = (location.state as { plotId: string })?.plotId;
		if (plotId) {
			setPlotId(plotId);
		}

		return () => {
			formik.resetForm();
			setObservationTypes([]);
			setObservationId("");
			setPrevObservations([]);
			setPrevObservationOptions([]);
			setShowPrevObservations(false);
			setShowDescription(false);
		}
	}, [location]); // eslint-disable-line

	useEffect(() => {
		if (!!plotId) {
			fetchCropDetails();
		}
	}, [plotId]); // eslint-disable-line

	useEffect(() => {
		// fetch disease pest id
		if (cropDetails?.id) {
			fetchDiseasePests();
		}
	}, [cropDetails]); // eslint-disable-line

	useEffect(() => {
		// fetch observation type

		if (!cropDetails?.id || !formik.values.diseasePestId) return;
		fetchObservationType(cropDetails!.id, formik.values.diseasePestId);
	}, [formik.values.diseasePestId]); // eslint-disable-line

	// update description for disease pest  and observation type fields based on selected values
	useEffect(() => {
		resetSampleReading();
		updateDescriptionModalData();
		fetchPreviousSessions();
	}, [formik.values.observationTypeId]) // eslint-disable-line

	useEffect(() => {

		const updatedDateString = moment(formik.values.observationDate).toISOString();
		if(!updatedDateString) {
			return;
		}

		resetModelPrediction();
		fetchPreviousSessions(updatedDateString);
	}, [formik.values.observationDate]) // eslint-disable-line

	useEffect(() => {
		if(canSubmitReadings) {
			submitReadings();
		}
	}, [canSubmitReadings]); // eslint-disable-line

	useEffect(() => {
		if (prevObservations?.length > 0) {
			generatePrevObservationsOptions();
			setObservationId(prevObservations[prevObservations.length - 1].id);
			observationDateRef.current = prevObservations[prevObservations.length - 1].observationDate;
			if (prevObservations[prevObservations.length - 1]?.previousSpray) {
				formik.setFieldValue('previousSpray', prevObservations[prevObservations.length - 1].vegetationReading.previousSpray);
				formik.setFieldValue('previousSprayDate', prevObservations[prevObservations.length - 1].vegetationReading.previousSprayDate);
			}
			formik.setFieldValue('modelPrediction', prevObservations[prevObservations.length - 1].modelPrediction ?? null)
			formik.setFieldValue('modelRiskLevel', prevObservations[prevObservations.length - 1].modelRiskLevel ?? null);
			formik.setFieldValue('vegetationReading', prevObservations[prevObservations.length - 1].vegetationReading ?? []);
		} else {
			formik.setFieldValue('previousSpray', '');
			formik.setFieldValue('previousSprayDate', '');
			formik.setFieldValue('vegetationReading', []);
			resetModelPrediction();
			setShowPrevObservations(false);
			setObservationId(undefined);
		}
	}, [prevObservations]); // eslint-disable-line

	// const closePage = () => {
	// 	navigateTo("/tabs/plots", {});
	// }

	const resetSampleReading = () => {
		resetPrevObservations();
		formik.setValues({
			observationTypeId: formik.values.observationTypeId,
			diseasePestId: formik.values.diseasePestId,
			...defaultFormValues
		});
	}

	const resetPrevObservations = () => {
		setPrevObservationOptions([]);
		setPrevObservations([]);
	}

	/**
	 * API Calls
	 */
	const fetchDiseasePests = async () => {
		if (!cropDetails) return;
		try {
			const diseasesPromise = getCropDiseases(cropDetails.id);
			const pestsPromise = getCropPests(cropDetails.id);

			Promise.allSettled([diseasesPromise, pestsPromise]).then(
				([diseasesResponse, pestsResponse]: any) => {
					const diseases = diseasesResponse.value;
					const pests = pestsResponse.value;

					const mergedArray = [...diseases, ...pests].reduce((acc, current) => {
						const existingItem = acc.find(
							(item: any) =>
								item.id === current.diseaseId || item.id === current.pestId
						);

						if (!existingItem) {
							// If the item is not already in the result array, add it
							const newItem = {
								displayName: current.displayName,
								id: current.diseaseId || current.pestId || 0, // Use diseaseId or pestId as id
							};
							acc.push(newItem);
						}

						return acc;
					}, []);

					setDiseasePestIds(mergedArray);
				}
			);
		} catch (error) { }
	};

	const fetchCropDetails = async () => {
		if (!plotId) return;
		getPlot(plotId)
			.then(async (res) => {
				let { cropId, activeStage, name, stageStartDate, varietyId } =
					res.cropsSown;

				const startDate = Array.isArray(stageStartDate)
					? stageStartDate.at(-1)
					: stageStartDate;

				if (Number(cropId)) {
					cropId = await fetchNewCropId(cropId);
				}

				setCropDetails({
					id: cropId,
					name,
					activeStage,
					startDate,
					varietyId,
				});
			})
			.catch((error) => {
				console.error("fetch crop details");
			});
	};

	const fetchNewCropId = async (cropId: string) => {
		try {
			const response = await getNewCropId(cropId);
			if (Array.isArray(response) && response.length > 0) {
				return response[0].newId;
			}
		} catch (error) {
			throw new Error("something went wrong");
		}
	};

	const fetchObservationType = async (
		cropId: string,
		diseasePestId: string
	) => {
		try {
			const response = await getObservationType(cropId, diseasePestId);
			setObservationTypes(response);
			if (!!response && response?.length > 0) {
				if (response?.length === 1)
					formik.values.observationTypeId = response[0].id;
				setScale(response[0].scale);
			}
		} catch (error) {
			console.log("Error in fetching observation: ", error);
		}
	};

	const fetchPreviousSessions = async (date: string = "") => {
		getPreviousSessions({
			diseasePestId: formik.values.diseasePestId,
			plotId: plotId!,
			observationTypeId: formik.values.observationTypeId,
			date
		})
			.then(res => {
				if (res.error) {
					throw new Error("Previous sessions: Something went wrong");
				}

				setPrevObservations(res);
			})
			.catch(error => {
				console.error(error);
			})
	}

	const submitReadings = async () => {
		// add other values
		const formikValues = cloneDeep(formik.values);
		let payload: any = {
			...formik.values,
			cropId: cropDetails?.id!,
			plotId,
			cropStage: cropDetails?.activeStage!,
			seasonStartDate: cropDetails?.startDate!
		}

		payload.observationDate = new Date(payload.observationDate).toISOString();

		if (payload.previousSprayDate || payload.previousSpray) {
			payload.previousSprayDate = new Date(payload.previousSprayDate).toISOString();
		} else {
			const { previousSprayDate, previousSpray, ...otherProperties } = payload;
			payload = otherProperties;
		}

		if(!payload.modelRiskLevel) {
			delete payload.modelRiskLevel;
			delete payload.modelPrediction;
		}

		try {
			if (observationId) {
				payload.observationDate = observationDateRef.current;
				await putObservationData(observationId, payload);
				formik.setValues({ ...formikValues }); // handles: first reading after unselect last observation
			} else {
				const { id, error, modelPrediction, modelRiskLevel } = await postObservationData(payload);

				if(!!error) {
					throw new Error(error);
				}

				setObservationId(id);
				formik.setValues({ ...formikValues, modelPrediction, modelRiskLevel });
				observationDateRef.current = payload.observationDate;
			}
			postSubmitSuccess();
		} catch (error) {
			showErrorToast("Something went wrong");
			console.log(error);
		}
	}

	const updateDescriptionModalData = () => {
		const observationType = observationTypes?.filter((ot) => ot.id === formik.values.observationTypeId);
		if (observationType && observationType?.length > 0) {
			setDescription(observationType[0].description);
		}
	}

	const generatePrevObservationsOptions = () => {
		const options = prevObservations?.length > 0 ? prevObservations
			.map((o: any) => ({ value: o.id, label: o.observationDate })) : [];

		setPrevObservationOptions(options);
		if (options.length >= 0) {
			setTimeout(() => {
				openPrevObservationModal();
			}, 100);
		}
	}

	const postSubmitSuccess = () => {
		showSuccessToast("Data Saved Successfully");
		setCanSubmitReadingsFalse();
	}

	const openDescription = () => {
		setShowDescription(true);
	}

	const closeDesciption = () => {
		setShowDescription(false);
	}

	const openPrevObservationModal = () => {
		setShowPrevObservations(true);
	}

	const handlePrevObservationSelect = (e: any) => {
		const observationId = e.detail.value;

		if (!observationId) {
			setObservationId(observationId);
			return;
		}

		setObservationId(observationId);

		const observation = prevObservations.filter(observation => observation.id === observationId)[0];

		if (observation?.previousSpray) {
			formik.setFieldValue('previousSpray', observation.previousSpray);
			formik.setFieldValue('previousSprayDate', observation.previousSprayDate);
		}

		observationDateRef.current = observation?.observationDate; // to preserve observation date of selected observation, will use this at time of saving observation
		formik.setFieldValue('vegetationReading', observation?.vegetationReading ?? []);
		formik.setFieldValue('modelPrediction', observation?.modelPrediction ?? null);
		formik.setFieldValue('modelRiskLevel', observation?.modelRiskLevel ?? null);

		showSuccessToast("Observation selected successfully");
	}

	const resetModelPrediction = () => {
		formik.setFieldValue('modelPrediction', null);
		formik.setFieldValue('modelRiskLevel', null);	
	}

	const setCanSubmitReadingsTrue = () => setCanSubmitReadings(true);
	const setCanSubmitReadingsFalse = () => setCanSubmitReadings(false);

	/**
	 * Toast handlers
	 */
	const showErrorToast = (message: string) => {
		setErrorToast({ isOpen: true, message })
	}

	const showSuccessToast = (message: string) => {
		setSuccessToast({ isOpen: true, message })
	}

	const closeSuccessToast = () => setSuccessToast({ isOpen: false, message: "" });
	const closeErrorToast = () => setErrorToast({ isOpen: false, message: "" });

	/**
	 * rendering states/variables
	 */
	const renderObservationType =
		formik.values?.observationTypeId?.length > 0
			? observationTypes?.filter(
				(item: any) => item.id === formik.values.observationTypeId
			)
			: [];

	const vegetationIndex = formik.values.vegetationReading.length + 1;

	const clearOptions = () => {
		setObservationId("");
		resetModelPrediction();
		formik.setFieldValue("vegetationReading", []);
		formik.setFieldValue("previousSpray", "");
		formik.setFieldValue("previousSprayDate", "");
	}

	return (
		<IonPage className="ion-padding">
			<IonHeader>
				<IonToolbar>
					<IonTitle> Add Observation </IonTitle>
					<IonButtons slot="start">
						{/* <IonIcon icon={arrowBack} onClick={closePage}></IonIcon> */}
						<IonBackButton defaultHref="/" />
					</IonButtons>
				</IonToolbar>
			</IonHeader>

			<IonContent>
				<div>
					<IonItem>
						<IonLabel>Disease/Pest</IonLabel>
						<IonSelect
							name="diseasePestId"
							placeholder="Select"
							value={formik.values.diseasePestId}
							onIonChange={formik.handleChange}
							interface="action-sheet"
						>
							{Array.isArray(diseasePestIds) &&
								(diseasePestIds as any[]).map((diseasePest, index) => (
									<IonSelectOption key={diseasePest.id + index} value={diseasePest.id}>
										{diseasePest.displayName}
									</IonSelectOption>
								))}
						</IonSelect>
					</IonItem>
					<IonItem>
						<IonLabel>Observation</IonLabel>
						<IonSelect
							name="observationTypeId"
							placeholder="Select"
							value={formik.values.observationTypeId === "" ? "" : formik.values.observationTypeId}
							interface="action-sheet"
							onIonChange={(e) => {
								formik.handleChange(e);
							}}
						>
							{Array.isArray(observationTypes) &&
								(observationTypes as any[]).map((observationType) => (
									<IonSelectOption
										key={observationType.id}
										value={observationType.id}
									>
										{observationType.name}
									</IonSelectOption>
								))}
						</IonSelect>
					</IonItem>
					<IonItem>
						<IonLabel>Date</IonLabel>
						<IonDatetime
							name="observationDate"
							placeholder="Date"
							displayFormat="DD/MM/YYYY"
							onIonChange={formik.handleChange}
							value={formik.values.observationDate}
							max={moment().toISOString()}
						/>
					</IonItem>

					{
						showPrevObservations && (
							<IonItem>
								<IonLabel> Last Observations (36 hours) </IonLabel>
								<IonSelect
									placeholder="Select an observation to continue"
									onIonChange={handlePrevObservationSelect}
									value={observationId === " " ? prevObservationOptions![prevObservationOptions!.length - 1].value : observationId}
								>
									{prevObservationOptions?.map((observation, index) => (
										<IonSelectOption key={`observation-dropdown-${observation.value}-${index}`} value={observation.value}>
											{moment(observation.label).format("lll")}
										</IonSelectOption>
									))}

								</IonSelect>

								{
									!!observationId && <IonButton onClick={clearOptions}>
										<IonIcon icon={closeOutline} />
									</IonButton>
								}
							</IonItem>
						)
					}

					{!!renderObservationType && renderObservationType?.length > 0 && (
						<div className="vegetation-reading-container">
							<IonItem lines="none">
								<IonLabel>Vegetation Readings</IonLabel>

								<IonIcon size="small" onClick={openDescription} icon={informationCircle} />
							</IonItem>

							<IonContent className="ion-padding" fullscreen>
								<ObservationReadingTemplate
									plotId={plotId!}
									cropId={cropDetails?.id!}
									diseasePestId={formik.values.diseasePestId}
									formik={formik}
									name={vegetationIndex}
									columns={renderObservationType[0].columns ?? []}
									subColumns={renderObservationType[0].subColumns ?? []}
									showErrorToast={showErrorToast}
									showSuccessToast={showSuccessToast}
									submitReading={setCanSubmitReadingsTrue}
									resetForm={formik.resetForm}
									scaleValues={scale}
								/>
							</IonContent>
						</div>
					)}
				</div>
			</IonContent>

			<IonToast
				isOpen={!!successToast?.isOpen}
				message={successToast?.message!}
				duration={2000}
				color="success"
				onDidDismiss={closeSuccessToast}
			/>

			<IonToast
				isOpen={!!errorToast?.isOpen}
				message={errorToast?.message!}
				duration={2000}
				color="danger"
				onDidDismiss={closeErrorToast}
			/>

			<IonModal isOpen={showDescription} onDidDismiss={closeDesciption}>
				<div className="description-modal">
					<div>
						<div className="description-modal-header"> Description </div>
						<div className="description-container">
							{description}

							{/* {scale table} */}
							<IonGrid style={{ fontSize: '14px', marginTop: '30px' }}>
								<IonRow style={{ borderBottom: '1px solid #ccc', textAlign: 'center' }}>
									<IonCol style={{ padding: '16px' }}><b>Min</b></IonCol>
									<IonCol style={{ padding: '16px' }}><b>Max</b></IonCol>
									<IonCol style={{ padding: '16px' }}><b>Label</b></IonCol>
								</IonRow>

								{scale!.map((row, index) => (
									<IonRow key={index} style={{ borderBottom: '1px solid #ccc', textAlign: 'center' }}>
										<IonCol style={{ padding: '16px' }}>
											{row[1] !== null ? row[1] : "-"}
										</IonCol>
										<IonCol style={{ padding: '16px' }}>
											{row[2] !== null ? row[2] : "-"}
										</IonCol>
										<IonCol style={{ padding: '16px' }}>
											{scaleToLabel[index]}
										</IonCol>

									</IonRow>
								))}
							</IonGrid>

						</div>
					</div>
					<div className="description-close-button-container">
						<IonButton color="danger" onClick={closeDesciption}> Close </IonButton>
					</div>
				</div>
			</IonModal>
		</IonPage>
	);
};




export default DiseasePestObservation;
