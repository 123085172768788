import React, { useEffect, useState } from "react";
import { Network } from "@capacitor/network";
import { FaWifi} from "react-icons/fa";
import { MdSignalWifiConnectedNoInternet0 } from "react-icons/md";


const NetworkStatus = ({ onNetworkChange }:any) => {
  const [connected, setConnected] = useState(true);
  const [show, setShow] = useState(false);  

  const statusConfig = connected
  ? {
      color: "green",
      icon: <FaWifi />,
      message: "You are online",
    }
  : {
      color: "red",
      icon: <MdSignalWifiConnectedNoInternet0 />,
      message: "No connection",
    };

  useEffect(() => {
    const checkInitialNetworkStatus = async () => {
        const status = await Network.getStatus();  
        if(!status.connected){
            setShow(true);
            onNetworkChange(true); 
            setConnected(status.connected);
        }
      };
    checkInitialNetworkStatus();
    const listener = Network.addListener("networkStatusChange", (status) => {
      if (!connected && status.connected) {
        setShow(true);
        setTimeout(() => {
          setShow(false); 
          onNetworkChange(false); 
        //   setTimeout(() => {
        //     window.location.reload();  
        //   }, 2600);  
        }, 2000); 
      } else if (connected && !status.connected) {
        setShow(true);  
      }
      setConnected(status.connected);
    });

    return () => {
      listener.remove();
    };
  }, [connected,onNetworkChange]);

  const messageStyles: React.CSSProperties = {
    height: "3vh",
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: statusConfig.color,
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    transform: show ? "translateY(0)" : "translateY(100%)",
    transition: "transform 0.3s ease, opacity 0.3s ease",
    opacity: show ? 1 : 0,
  };

  return (
    <>
    {show && (
      <div style={messageStyles}>
        <div style={{ marginRight: "10px", fontSize: "1rem" }}>
          {statusConfig.icon}
        </div>
        <p style={{ fontSize: "0.7rem" }}>{statusConfig.message}</p>
      </div>
    )}
  </>
  );
};

export default NetworkStatus;
