import React, { useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonBackButton,
  IonText,
  IonButton,
  IonToast,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
  IonThumbnail,
  IonModal,
  IonActionSheet,
  IonImg,
  IonIcon,
} from "@ionic/react";
import { closeOutline, colorFill } from "ionicons/icons";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { uploadFile } from "../services/image.service";
import { useRecoilValue } from "recoil";
import { userProfileState } from "../services/state.service";
import ImageViewer from "../components/ImageViewer";

const PestImagesPage: React.FC = () => {
  const userProfile: any = useRecoilValue(userProfileState);
  const [error, setError] = useState<string | null>(null);
  const [uploading, setUploading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastColor, setToastColor] = useState<"success" | "danger">("success");
  const [uploadedImages, setUploadedImages] = useState<any[]>([]);
  const [selectedImages, setSelectedImages] = useState<{ file: File; preview: string }[]>([]);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [showImageModal, setShowImageModal] = useState(false);

  const handleOpenCamera = async (source: CameraSource) => {
    try {
      setError(null);
      if (source === CameraSource.Photos) {
        const images = await Camera.pickImages({
          quality: 90,
          limit: 20,
        });
        let index = 0;
        const imageFiles = await Promise.all(
          images.photos.map(async (photo) => {
            const file = await fetch(photo.webPath!)
              .then((res) => res.blob())
              .then(
                (blob) =>
                  new File([blob], `image_${index++}.jpg`, { type: blob.type })
              );
            return { file, preview: photo.webPath! };
          })
        );
        setSelectedImages(imageFiles);
        setShowPreviewModal(true);
      } else {
        const photo = await Camera.getPhoto({
          quality: 90,
          resultType: CameraResultType.DataUrl,
          source: source,
        });

        if (photo?.dataUrl) {
          const file = await fetch(photo.dataUrl)
            .then((res) => res.blob())
            .then((blob) => new File([blob], "captured_image.jpg", { type: blob.type }));
          setSelectedImages([{ file, preview: photo.dataUrl }]);
          setShowPreviewModal(true);
        }
      }
    } catch (err) {
      setToastMessage("Failed to capture image. Please try again.");
      setToastColor("danger");
      setShowToast(true);
    }
  };

  const handleUpload = async () => {
    setUploading(true);
    try {
        const responses = await Promise.allSettled(
          selectedImages.map(({ file }) => uploadFile("Pest_trap_Training", userProfile.username, file))
        );
        let successCount = 0;
        const failedUploads: {file : File; preview : string }[] = [];

        for (let index = 0; index < responses.length; index++) {
          const result = responses[index];
          if (result.status === "fulfilled" && result.value.ok) {
            const data = await result.value.json();
            setUploadedImages((prev) => [...prev, data]);
            successCount++;
          } else {
            failedUploads.push(selectedImages[index]);
            console.error(`Upload failed for image: ${selectedImages[index].file.name}`);
          }
        }
        setToastMessage(`${successCount}/${selectedImages.length} images uploaded successfully!`);
        setToastColor(successCount > 0 ? "success" : "danger");
        setShowToast(true);
        setSelectedImages(failedUploads);
    
        if (failedUploads.length === 0) {
          setShowPreviewModal(false);
        }
      } catch (err) {
        console.error("Error during image upload:", err);
        setToastMessage("An error occurred during image upload.");
        setToastColor("danger");
        setShowToast(true);
      } finally {
        setUploading(false);
      }
    };

  function deleteImage(url: string) {
    const imageIndex = uploadedImages.findIndex((item) => item.images[0] === url);
    if (imageIndex > -1) {
      const updatedImages = [...uploadedImages];
      updatedImages.splice(imageIndex, 1);
      setUploadedImages(updatedImages);
    }
  }

  const removeImage = (index: number) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/tabs/home" />
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Pest Images</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className="ion-padding">
          <IonText>
            <p>Click the button below to capture and upload pest images.</p>
          </IonText>
          <IonButton
            expand="block"
            color="primary"
            onClick={() => setShowActionSheet(true)}
            disabled={uploading}
          >
            {uploading ? "Uploading..." : "Add Images"}
          </IonButton>
          {error && (
            <IonText color="danger">
              <p>{error}</p>
            </IonText>
          )}
        </div>

        <IonActionSheet
          isOpen={showActionSheet}
          onDidDismiss={() => setShowActionSheet(false)}
          buttons={[
            {
              text: "Camera",
              handler: () => handleOpenCamera(CameraSource.Camera),
            },
            {
              text: "Gallery",
              handler: () => handleOpenCamera(CameraSource.Photos),
            },
            {
              text: "Cancel",
              role: "cancel",
            },
          ]}
        />

        {!!uploadedImages.length && (
          <>
            <IonItem lines="none">
              <IonLabel>Recently uploaded Images</IonLabel>
            </IonItem>
            <IonItem lines="none">
              <IonGrid>
                <IonRow>
                  {uploadedImages?.map((item: any, index: number) => (
                    <IonCol key={index} size="4">
                      <IonThumbnail>
                        <img
                          src={item?.thumbnails && item.thumbnails[0]}
                          onClick={(e) => {
                            setSelectedImage(item?.images && item.images[0]);
                            setShowImageModal(true);
                          }}
                        />
                      </IonThumbnail>
                    </IonCol>
                  ))}
                </IonRow>
              </IonGrid>
            </IonItem>
          </>
        )}

        <IonModal isOpen={showPreviewModal} onDidDismiss={() => setShowPreviewModal(false)}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Preview Images</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setShowPreviewModal(false)}>Close</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonGrid>
              <IonRow>
                {selectedImages.map((image, index) => (
                  <IonCol size="6" key={index} style={{ position: "relative" }}>
                    <IonImg
                      src={image.preview}
                      style={{ height: "150px", width: "100%", objectFit: "cover", cursor: "pointer", padding: "2px" }}
                      onClick={() => {
                        setSelectedImage(image.preview);
                        setShowImageModal(true);
                      }}
                    />
                    <IonIcon
                      icon={closeOutline}
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        fontSize: "20px",
                        color:"black",
                        background: "white",
                        borderRadius: "50%",
                        padding: "2px",
                        cursor: "pointer",
                      }}
                      onClick={() => removeImage(index)}
                    />
                  </IonCol>
                ))}
              </IonRow>
            </IonGrid>
            {selectedImages.length > 0 && (
              <IonButton expand="block"
                color={uploadedImages.length > 0 ? "secondary" : "primary"}
                onClick={handleUpload}
                disabled={uploading}>
                {uploadedImages.length > 0 ? "Retry Uploading" : "Upload Images"}
              </IonButton>
            )}
          </IonContent>
        </IonModal>

        <IonModal isOpen={showImageModal} cssClass="my-custom-class">
          <ImageViewer
            imageUrl={selectedImage}
            onDeleteHandler={deleteImage}
            onCloseHandler={setShowImageModal}
          />
        </IonModal>
      </IonContent>

      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={20000}
        color={toastColor}
        buttons={[
          {
            text: 'Close',
            role: 'cancel',
            handler: () => {
              console.log('Dismiss clicked');
            },
          },
        ]}
      />
    </IonPage>
  );
};

export default PestImagesPage;
