/**
 * A utility function to extract fields of a specific type from an array of objects.
 * 
 * @param type - The type of fields to extract. For example, "string", "number", etc.
 * @returns A function that accepts an array of objects and returns an array of field names
 *          that match the specified type from the last object in the array.
 * 
 * @example
 *  -- If you want to extract all numeric fields from device data --
 * const extractNumericFields = createFieldExtractor("number");
 * const numericFields = extractNumericFields(deviceGraphs);
 * 
 *  -- If you want to extract all string fields from device data --
 * const extractStringFields = createFieldExtractor("string");
 * const stringFields = extractStringFields(deviceGraphs);
 */
export const createFieldExtractor = (type: string) => {
    return (data: any[]): any[] => {
      if (!data || data.length === 0) return [];
      const lastElement = data[data.length - 1];
      return Object.keys(lastElement).filter(
        (key) =>
          lastElement[key] !== null &&
          lastElement[key] !== undefined &&
          typeof lastElement[key] === type
      );
    };
};