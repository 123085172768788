import React, { useState } from "react";
import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonButton,
  IonIcon,
  IonCardContent,
  IonModal,
  IonLabel,
} from "@ionic/react";
import { shareOutline, downloadOutline, trashOutline } from "ionicons/icons";
import useNavigation from "../hooks/useNavigation";
import moment from "moment";
import { useEventStore } from "../store/useEventStore";
import { FarmerDataT } from "../store/types.store";
import { downloadExcelSheet } from "../utilities/xlxs";
import { useLoader } from "../contexts/LoaderContext";
import { useToast } from "../contexts/ToastContext";

interface EventCardProps {
  id: number | string;
  name: string;
  numberOfFarmers: number;
  date: string;
  farmers: FarmerDataT[];
}

const orderOfHeader = {
  id: "ID",
  name: "Name",
  mobile: "Mobile",
  village: "Village",
  crops: "Crops",
  comments: "Comments",
};

const EventCard: React.FC<EventCardProps> = ({
  id,
  name,
  numberOfFarmers,
  date,
  farmers,
}) => {
  const navigateTo = useNavigation();
  const { deleteEvent } = useEventStore.getState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { showLoader, hideLoader } = useLoader();

  const handleDownload = (e: React.MouseEvent) => {
    e.stopPropagation();
    
    const formattedDate = moment(date).format("DD-MMM-YYYY").toUpperCase();
    const filename = `${name}_${formattedDate}`;

    if (!!farmers && farmers.length > 0) {
      showLoader("Downloading");
      downloadExcelSheet(filename, farmers, orderOfHeader);
      hideLoader();
    }
    
  };

  const handleDelete = async (
    eventId: number | string,
    event: React.MouseEvent
  ) => {
    event.stopPropagation();
    deleteEvent(eventId);
    setShowDeleteModal(false);
    window.location.reload();
  };

  const openDeleteModal = (event: React.MouseEvent) => {
    event.stopPropagation();
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleCardClick = (eventId: number | string) => {
    navigateTo("/tabs/event-farmer-form", { eventId: eventId });
  };

  return (
    <>
      <IonCard className="" onClick={() => handleCardClick(id)}>
        <IonCardHeader>
          <IonCardSubtitle style={{ fontSize: "0.6rem" }}>
            Date: {moment(date).format("DD MMM YYYY")}
          </IonCardSubtitle>
          <IonCardTitle
            style={{
              fontSize: "1.3rem",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              marginBottom: "10px",
            }}
          >
            {name.length > 20 ? `${name.slice(0, 20)}...` : name}
          </IonCardTitle>
        </IonCardHeader>

        <IonCardContent>
          <p className="ion-margin-bottom">
            👩‍🌾 Farmers Registered: <strong>{numberOfFarmers}</strong>
          </p>

          <div
            className="ion-margin-top ion-flex ion-justify-content-end ion-align-items-center"
            style={{ display: "flex" }}
          >
            <IonButton
              color={farmers.length === 0 ? "medium" : "secondary"}
              fill="outline"
              onClick={(e) => handleDownload(e)}
              size="small"
            >
              <IonIcon icon={downloadOutline} slot="start" />
              Download
            </IonButton>
            <IonButton
              color="danger"
              fill="outline"
              onClick={openDeleteModal}
              size="small"
            >
              <IonIcon icon={trashOutline} slot="start" />
              Delete
            </IonButton>
          </div>
        </IonCardContent>
      </IonCard>

      <IonModal isOpen={showDeleteModal} onDidDismiss={closeDeleteModal}>
        <div className="ion-padding">
          <IonLabel>Are you sure you want to delete this event?</IonLabel>
          <div className="ion-margin-top">
            <IonButton
              color="danger"
              expand="block"
              onClick={(e) => handleDelete(id, e)}
            >
              Confirm Delete
            </IonButton>
            <IonButton color="light" expand="block" onClick={closeDeleteModal}>
              Cancel
            </IonButton>
          </div>
        </div>
      </IonModal>
    </>
  );
};

export default EventCard;
