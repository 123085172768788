import React from "react";
import { LoaderProvider } from "./LoaderContext";
import { ToastProvider } from "./ToastContext";

const IndexProvider: React.FC = ({ children }) => {
  return (
    <LoaderProvider>
      <ToastProvider>
        {children}
      </ToastProvider>
    </LoaderProvider>
  );
};

export default IndexProvider;
