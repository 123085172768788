import React, { useState } from 'react';
import { IonButton, IonButtons, IonCard, IonChip, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonModal, IonRow, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import useNavigation from '../hooks/useNavigation';
import { bugOutline, call, navigate, pulseOutline } from 'ionicons/icons';
import MapViewSelector from './MapViewSelector';
import Map from '../pages/GoogleMap';
import { updatePlotDetails } from '../services/plot.service';
import { makeCall } from '../hooks/common';

interface IProps {
    plot: any;
    headerIconOnClickHandler: Function;
}

const PlotCard: React.FC<IProps> = (props) => { 
    const navigateTo = useNavigation();
    const [showActionSheet, setShowActionSheet] = useState(false);
    const [showMap, setShowMap] = useState<boolean>(false);
    const [latLng, setLatLng] = useState<[number, number]>();
    
    function mapSelector(e: any, lat: number, lng: number) {
        e.stopPropagation();
        setShowActionSheet(true);
    }

    function openMap(lat: number, lng: number) {
        setShowMap(true);
        setLatLng([lat, lng]);
    }

    function closeMap() {
        setShowMap(false);
    }

    async function onPlotsLocationChange(payload: any) {
        await updatePlotDetails(props.plot.plotId, payload);
    }

    async function onPlotsGeoBoundaryChange(payload: any) {
        await updatePlotDetails(props.plot.plotId, payload);
    }

    function addDiseasePestObservation(e: React.MouseEvent<HTMLIonIconElement, MouseEvent>) {
        e.preventDefault();
        e.stopPropagation();
        const plotId = props.plot.plotId || props.plot._id;
        navigateTo('/add-disease-pest-observation', { plotId });
    }


    return (
        <>
            <IonCard onClick={() => navigateTo('/tabs/plot', { plotId: props.plot.plotId || props.plot._id })}>
                <IonItem>
                    <IonLabel>{props.plot.plotId || props.plot._id}</IonLabel>
                    <div style={{ width: "15%", display: "flex", justifyContent: "space-between"}} >
                        <IonIcon icon={bugOutline} onClick={addDiseasePestObservation}></IonIcon>
                        <IonIcon icon={pulseOutline} onClick={e => props.headerIconOnClickHandler(e, props.plot)}></IonIcon>
                    </div>
                </IonItem>
                <IonItem lines='none'>
                    <IonLabel>
                        <h2>Name: {props.plot.name}</h2>
                    </IonLabel>
                </IonItem>
                {props.plot.farmId && <IonItem lines='none' >
                    <IonLabel>
                        <h2>Farm:  <a onClick={e => { e.stopPropagation(); navigateTo('/tabs/farm', { farmId: props.plot.farmId }) }}>{props.plot?.farm?.name  || props.plot.farmId}</a></h2>
                    </IonLabel>
                </IonItem>}
                {props.plot.farmUserId && <IonItem lines='none' >
                    <IonLabel>
                        <h2>Farm User: {props.plot.farmUserId}</h2> 
                    </IonLabel>
                </IonItem>}
                {props.plot?.farm?.farmUser?.name && <IonItem lines='none' >
                    <IonLabel>
                        <h2>Farm User: {props.plot?.farm?.farmUser?.name} {<IonIcon icon={call} onClick={e => makeCall(e as any, props.plot?.farm?.farmUser?.mobile)}/>}</h2> 
                    </IonLabel>
                </IonItem>}
                <IonItem lines='none'>
                    <IonLabel>
                        <h2>Place: {props.plot.place}{props.plot.taluka ? ', ' + props.plot.taluka : ''}{props.plot.district ? ', ' + props.plot.district : ''}</h2>
                    </IonLabel>
                </IonItem>
                <IonItem lines='none'>
                    <IonLabel>
                        <h2>Created On: {new Intl.DateTimeFormat('en-GB', {
                            month: 'short',
                            day: '2-digit',
                            year: 'numeric',
                        }).format(new Date(props.plot.created_date))}</h2>
                    </IonLabel>
                </IonItem>
                {props.plot?.servicedBy && <IonItem lines='none' >
                    <IonLabel>
                        <h2>Serviced By: {props.plot.servicedBy}</h2>
                    </IonLabel>
                </IonItem>
                }
                <IonItem lines='none'>
                    <IonLabel onClick={e => mapSelector(e, props.plot.location.lat, props.plot.location.lng)}>
                        <span>Distance: {props.plot?.distanceInMeters ? ((props.plot.distanceInMeters / 1000).toFixed(2) + ' Km') : 'Not Available'}</span>
                        <IonIcon icon={navigate} />
                    </IonLabel>
                </IonItem>
                {props.plot?.plotStatus && <IonItem lines='none'>
                    <IonLabel color={!props.plot?.plotStatus?.isActive ? "danger" : undefined}>{!props.plot?.plotStatus?.isActive ? "Expired" : "Expiring"} on {new Intl.DateTimeFormat('en-GB', {
                        month: 'short',
                        day: '2-digit',
                        year: 'numeric',
                    }).format(new Date(props.plot?.plotStatus?.expiryDate))}</IonLabel>
                </IonItem>
                }
                {props.plot?.sensors && <IonItem lines='none'>
                    <IonGrid>
                        <IonRow>
                            {Object.keys(props.plot?.sensors || {}).length > 0 && Object.keys(props.plot?.sensors || {}).map((key: any, index: number) =>
                                <>
                                    {props.plot.sensors[key]?.values && <IonCol key={index}>
                                        <IonChip outline={true} color="primary" onClick={e => props.headerIconOnClickHandler(e, props.plot, key)}>
                                            <IonLabel>{key}</IonLabel>
                                        </IonChip>
                                    </IonCol>}
                                </>
                            )}
                            {props.plot.sensors.length === 0 &&
                                <IonCol><IonLabel class="secondary-text">No Sensors Found</IonLabel></IonCol>}
                        </IonRow>
                    </IonGrid>
                </IonItem>}
            </IonCard>
            <MapViewSelector isOpen={showActionSheet} withinAppHandler={openMap} onDismiss={setShowActionSheet} lat={props?.plot?.location?.lat} lng={props?.plot?.location?.lng}></MapViewSelector>

            <IonModal isOpen={showMap}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle size='small' style={{ fontWeight: 'bold' }}>
                            Plot Location Viewer
                        </IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={closeMap}>Close</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                    {
                        !!latLng && <Map 
                            plotId={props.plot.plotId} 
                            lat={latLng![0]} 
                            lng={latLng![1]} 
                            onPlotsLocationChange = {onPlotsLocationChange}
                            onPlotsGeoBoundaryChange = {onPlotsGeoBoundaryChange}
                            geoBoundary={props.plot?.geoBoundary?.coordinates[0] ?? []}
                        />
                    }
                </IonContent>
            </IonModal>
        </>
    );
};

export default PlotCard;
