import { create } from "zustand";
import {
  set as idbSet,
  get as idbGet,
  del as idbDel,
  clear as idbClear,
} from "idb-keyval";
import { EventT, FarmerDataT, EventStoreT } from "./types.store";

const STORE_KEY = "events-store";


export const useEventStore = create<EventStoreT>((set, get) => {
  (async () => {
    const persistedEvents = await idbGet(STORE_KEY);
    set({ events: persistedEvents || [] });
  })();

  return {
    events: [],

    addEvent: async (event: EventT) => {
      const updatedEvents = [...get().events, event];
      await idbSet(STORE_KEY, updatedEvents);
      set({ events: updatedEvents });
    },

    addFarmerToEvent: async (eventId: number | string, farmer: FarmerDataT) => {
      const updatedEvents = get().events.map((event) =>
        event.id === eventId
          ? {
              ...event,
              farmers: [...event.farmers, farmer],
              farmersCount: event.farmersCount + 1,
            }
          : event
      );
      await idbSet(STORE_KEY, updatedEvents);
      set({ events: updatedEvents });
    },

    deleteEvent: async (eventId: string | number) => {
      const currentEvents = [...get().events];

      const updatedEvents = currentEvents.filter(
        (event) => event.id !== eventId
      );

      await idbSet(STORE_KEY, updatedEvents);
      set({ events: updatedEvents });
    },

    clearStore: async () => {
      await idbClear();
      set({ events: [] });
    },
  };
});
