import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonChip,
  IonCol,
  IonContent,
  IonDatetime,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPicker,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonTextarea,
  IonThumbnail,
  IonTitle,
  IonToast,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
import { getFarmIdsOfUser } from "../services/farm.service";
import {
  getCropVarieties,
  searchPlot,
  getPlot,
  getSensors,
  getSupportedRootStocks,
  registerPlot,
  updatePlot,
  postpubliclyAccessible,
  getAvailableDevicesPaginated,
  deletePlotById,
  unassignIMEI,
  assignIMEI,
  updateAssignOfPlot,
} from "../services/plot.service";
import SelectMasterPlot from "./SelectMasterPlot";
import {
  handleErrors,
  Regex,
  toInputLowercase,
} from "../services/common.service";
import {
  GeolocationService,
  getElevation,
  getReverseGecodingAddress,
} from "../services/geolocation.service";
import { useLocation } from "react-router-dom";
import SelectItem from "./SelectItem";
import useNavigation from "../hooks/useNavigation";
import { useRecoilValue } from "recoil";
import {
  cropTypesState,
  deviceTypesState,
  plotTagsState,
  plotTypesState,
  userProfileState,
} from "../services/state.service";
import { addCircle, closeCircle, mapOutline, reload } from "ionicons/icons";
import LoadingSkeleton from "./LoadingSkeleton";
import { dataUrlToFile, takePicture, generateUniqueId } from "../hooks/common";
import { uploadFile } from "../services/image.service";
import ImageViewer from "./ImageViewer";
import "./CreatePlot.css";
import Map from "../pages/GoogleMap";
import {
  getInventoryItemsPaginated,
  getInventoryLocationById,
  getInventoryLocationsPaginated,
  deductInventory,
  deductInventoryRental,
  addToRentalInventory,
  getInventoryItemsOfLocation,
} from "../services/device.service";
import UserList from "../pages/UserList";
import { getOrganizations } from "../services/organization.service";
import { Scanner } from '@yudiel/react-qr-scanner';
import TransferToFarm from "./TransferToFarm";

const FarmPicker = {
  name: "FarmId",
  options: [] as any[],
};

interface Organization {
  id: string;
  name: string;
}

const setFarmPicker = (farmIds: any[]) => {
  FarmPicker.options = [];
  if (farmIds.length) {
    farmIds.forEach((item) => {
      FarmPicker.options.push({
        text: item.farmId,
        value: item.farmId,
      });
    });
  }
};

const CreatePlot = () => {
  const navigateTo = useNavigation();
  const firstRender = useRef(true);
  const location = useLocation();
  const [plot, setPlot] = useState<any>();
  const formik = useFormik({
    initialValues: {
      edit: false,
      farmUserId: "",
      farmId: "",
      name: "",
      plotId: "",
      place: "",
      taluka: "",
      district: "",
      area: "",
      crop: "",
      varietyId: "",
      sowingDate: "",
      deviceId: "",
      imei: "",
      dataInterval: "",
      soilMoisture1Depth: "",
      soilMoisture2Depth: "",
      rowDistance: "",
      plantDistance: "",
      perPlantDripper: "",
      dripperDistance: "",
      dripperDischarge: "",
      rootWidth: "",
      rootDepth: "",
      soilRetention: "",
      plantationYear: "",
      rootStock: "",
      altitude: "",
      location: {
        lat: "",
        lng: "",
      },
      soilTypeId: "",
      plotType: "",
      deviceType: "",
      sensors: [] as string[],
      hiddenSensors: [] as string[],
      assignMasterPlot: false,
      publiclyAccessible: false,
      parentId: "",
      inventoryItem: { name: "" as string, id: "" as string },
      inventoryLocation: { name: "" as string, id: "" as string },
      inventoryStock: 0,
      servicedBy: "",
      orgId: "",
      saleType:"sold",
      irrigationMethod:"drip",
      tags: [] as string[],
      attachments: {
        images: [] as string[],
        thumbnails: [] as string[],
      },
      geoBoundary: {
        type: "Polygon",
        coordinates: [],
      },
    },
    validationSchema: Yup.object({
      farmUserId: Yup.string().trim().required("Required"),
      farmId: Yup.string().trim().required("Required"),
      name: Yup.string().trim().required("Required"),
      plotId: Yup.string().when("edit", {
        is: (val: boolean) => !val,
        then: Yup.string()
          .trim()
          .required("Required")
          .matches(Regex.id, "Id can only contain lowercase chars and numbers"),
      }),
      // place: Yup.string().trim().required("Required"),
      area: Yup.number().required("Required").min(0),
      crop: Yup.string().trim().required("Required"),
      sowingDate: Yup.string().trim().required("Required"),
      deviceId: Yup.string().trim().required("Required"),
      inventoryItem: Yup.object().when("edit", {
        is: false,
        then: Yup.object().shape({
          name: Yup.string().trim().required("Required"),
        }),
      }),
      inventoryLocation: Yup.object().when("edit", {
        is: false,
        then: Yup.object().shape({
          name: Yup.string().trim().required("Required"),
        }),
      }),
      inventoryStock: Yup.number().when("edit", {
        is: false,
        then: Yup.number().min(1, "Item Not Available"),
      }),
      // dataInterval: Yup.number().required("Required").min(0),
      soilMoisture1Depth: Yup.number().min(0),
      soilMoisture2Depth: Yup.number().min(0),
      rowDistance: Yup.number().min(0),
      plantDistance: Yup.number().min(0),
      dripperDischarge: Yup.number().min(0),
      perPlantDripper: Yup.number().min(0),
      dripperDistance: Yup.number().min(0),
      rootWidth: Yup.number().min(0),
      rootDepth: Yup.number().min(0),
      soilRetention: Yup.number().min(0),
      soilTypeId: Yup.string().trim().required("Required"),
      plotType: Yup.string().trim().required("Required"),
      deviceType: Yup.string().trim().required("Required"),
      servicedBy: Yup.string().when("edit", {
        is: (val: boolean) => !val,
        then: Yup.string().trim().required("Required"),
      }),
      sensors: Yup.array().min(1),
      assignMasterPlot: Yup.boolean().when("parentId", {
        is: (val: string) => !val,
        then: Yup.boolean().oneOf([false], "Select Master Plot or untoggle"),
      }),
      publiclyAccessible: Yup.boolean(),
      altitude: Yup.string().trim().required('Required'),
      location: Yup.object({
        lat: Yup.string()
          .trim()
          .required("Required")
          .matches(Regex.latitude, "Invalid latitude"),
        lng: Yup.string()
          .trim()
          .required("Required")
          .matches(Regex.longitude, "Invalid longitude"),
      }),
      tags: Yup.array().min(1),
      saleType: Yup.string().trim().required("Required"),
    }),
    onSubmit: (values) => {
      setShowConfirmation(true);
    },
  });

  function setPlotValues(currentPlot: any) {
    if (currentPlot) {
      formik.setValues({
        edit: true,
        farmUserId: currentPlot.farmUserId,
        farmId: currentPlot.farmId,
        name: currentPlot.name,
        plotId: currentPlot.plotId,
        place: currentPlot.place,
        taluka: currentPlot.taluka,
        district: currentPlot.district,
        area: currentPlot.area,
        crop: currentPlot.cropsSown?.cropId,
        varietyId: currentPlot.cropsSown?.varietyId,
        sowingDate: currentPlot.cropsSown?.sowingDate,
        deviceId: currentPlot.device?.deviceId,
        imei: currentPlot.device?.imei,
        dataInterval: currentPlot.device?.dataInterval,
        soilMoisture1Depth: currentPlot.device?.soilMoisture1Depth,
        soilMoisture2Depth: currentPlot.device?.soilMoisture2Depth,
        rowDistance: currentPlot.rowDistance,
        plantDistance: currentPlot.plantDistance,
        perPlantDripper: currentPlot.perPlantDripper,
        dripperDistance: currentPlot.dripperDistance,
        dripperDischarge: currentPlot.dripperDischarge,
        plantationYear: currentPlot.plantationYear,
        rootWidth: currentPlot.rootWidth,
        rootDepth: currentPlot.rootDepth,
        soilRetention: currentPlot.soilRetention,
        rootStock: currentPlot.rootStock,
        altitude: currentPlot.altitude,
        location: {
          lat: currentPlot.location?.lat,
          lng: currentPlot.location?.lng,
        },
        soilTypeId: currentPlot.soilTypeId,
        inventoryItem: currentPlot.inventoryItem || { name: "" as string },
        inventoryLocation: currentPlot.inventoryLocation || {
          name: "" as string,
        },
        inventoryStock: 0,
        irrigationMethod: currentPlot.irrigationMethod,
        plotType: currentPlot.plotType,
        deviceType: currentPlot.device?.deviceType,
        sensors: currentPlot.device?.sensors
          ? [...currentPlot.device.sensors]
          : [],
        hiddenSensors: currentPlot.hiddenSensors
          ? [...currentPlot.hiddenSensors]
          : [],
        assignMasterPlot: !!currentPlot.parentId,
        publiclyAccessible: !!currentPlot.publiclyAccessible,
        parentId: currentPlot.parentId,
        servicedBy: currentPlot.servicedBy,
        orgId: currentPlot.orgId,
        saleType: currentPlot.saleType,
        tags: currentPlot.tags || [],
        attachments: currentPlot.attachments || {
          images: [] as string[],
          thumbnails: [] as string[],
        },
        geoBoundary: currentPlot.geoBoundary,
      });
      // Set the toggle state based on whether currentPlot and device exist
      setIsToggled(currentPlot?.device ? currentPlot.device.isAssigned : false);

      // Show the toggle only if currentPlot exists and has a device
      setshowToggled(!!(currentPlot && currentPlot.device));
    }
    setPlot(currentPlot);
  }

  function setInitialLatLng() {
    const geoService = new GeolocationService();
    geoService.getCurrentPosition().then((geolocation) => {
      formik.setFieldValue(
        "location.lat",
        geolocation!.coords.latitude.toFixed(6) ?? ""
      );
      formik.setFieldValue(
        "location.lng",
        geolocation!.coords.longitude.toFixed(6) ?? ""
      );
      formik.setFieldValue('altitude', geolocation!.coords.altitude ?? '');
      if (geolocation?.coords.latitude && geolocation?.coords.longitude) {
        getReverseGecodingAddress(
          geolocation.coords.latitude,
          geolocation.coords.longitude
        ).then((resp) => {
          formik.setFieldValue(
            "place",
            resp?.address?.village ||
              resp?.address?.neighbourhood ||
              resp?.address?.suburb ||
              ""
          );
          formik.setFieldValue("taluka", resp?.address?.county || "");
          formik.setFieldValue("district", resp?.address?.state_district || "");
        });
        if (!geolocation?.coords?.altitude) {
          getElevation(geolocation.coords.latitude, geolocation.coords.longitude)
              .then(resp => {
                  formik.setFieldValue('altitude', resp?.results ? resp.results[0]?.elevation || '' : '');
              });
      }
      }
    });
  }

  const [loading, setLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [prefilled, setPrefilled] = useState(false);
  const [onSuccess, setOnSuccess] = useState("");
  const [onError, setOnError] = useState("");
  const plotTypes = useRecoilValue(plotTypesState);
  const cropTypes = useRecoilValue(cropTypesState);
  const deviceTypes = useRecoilValue(deviceTypesState);
  const userProfile: any = useRecoilValue(userProfileState);
  const plotTags = useRecoilValue(plotTagsState);
  const [disableDeleteButton, setDisableDeleteButton] =
    useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [loadingOrganizations, setLoadingOrganizations] = useState(true);

  const [isToggled, setIsToggled] = useState(false);
  const [showToggle, setshowToggled] = useState(false);
  const [inventoryItems, setInventoryItems] = useState<any[]>([]);

  const plotId: any = (location.state as any)?.plotId ?? null;
  const farmUserId = (location.state as any)?.farmUserId ?? "";
  const soilTypes = [
    {
      name: "Sandy Loam",
      soilTypeId: "1",
      src: ["assets/Soil_1-1.jpg", "assets/Soil_1-2.jpg"],
      description:
        "If you take a handful of wet soil and press it, it will form a ball. When you press it with your thumb and forefinger, it will form a ribbon up to 2.5 cm in length.",
    },
    {
      name: "Fine Sandy Loam",
      soilTypeId: "4",
      src: ["assets/Soil_2-1.jpg", "assets/Soil_2-2.jpg"],
      description:
        "When you take a handful of wet soil and press it, it will form a ball, but it will break immediately when you open your hand.",
    },
    {
      name: "Clay",
      soilTypeId: "3",
      src: ["assets/Soil_3-1.jpg", "assets/Soil_3-2.jpg"],
      description:
        "When you take wet soil in your hand and press it, it will form a ball. If you press it with your thumb and fingers, it will form a ribbon up to 5 cm in length. The soil will also feel sticky.",
    },
  ];

  useEffect(() => {
    let mounted = true;
    setLoading(true);
    if (plotId) {
      getPlot(plotId).then((plot) => {
        if (mounted) {
          setPlotValues(plot);
          setPrefilled(true);
          setLoading(false);
          setDisableDeleteButton(plot.device);
        }
      });
    } else if (farmUserId) {
      formik.setFieldValue(
        "farmUserId",
        (location.state as any)?.farmUserId ?? ""
      );
      formik.setFieldValue("farmId", (location.state as any)?.farmId ?? "");
      setPrefilled(true);
      setInitialLatLng();
      setLoading(false);
    } else {
      setInitialLatLng();
      formik.setFieldValue("servicedBy", userProfile.username);
      setLoading(false);
    }
    return () => {
      mounted = false;
    };
  }, [plotId]);

  useEffect(() => {
    getOrganizations()
      .then((data) => {
        setOrganizations(data);
        setLoadingOrganizations(false);
      })
      .catch((err) => {
        setLoadingOrganizations(false);
      });
  }, []);

  const [farmPickerIsOpen, setFarmPickerIsOpen] = useState(false);
  useEffect(() => {
    let mounted = true;
    if (formik.values.farmUserId) {
      getFarmIdsOfUser(formik.values.farmUserId).then((items) => {
        if (mounted) {
          setFarmPicker(items);
        }
      });
    }
    return () => {
      mounted = false;
    };
  }, [formik.values.farmUserId]);

  const [rootStockTypes, setRootStockTypes] = useState([] as any[]);
  useEffect(() => {
    let mounted = true;
    if (formik.values.crop) {
      getSupportedRootStocks(formik.values.crop).then((items) => {
        if (mounted) {
          setRootStockTypes(items);
        }
      });
    }
    return () => {
      mounted = false;
    };
  }, [formik.values.crop]);

  const [cropVarieties, setCropVarieties] = useState([] as any[]);
  useEffect(() => {
    let mounted = true;
    if (formik.values.crop) {
      getCropVarieties(formik.values.crop).then((items) => {
        if (mounted) {
          setCropVarieties(items);
        }
      });
    }
    return () => {
      mounted = false;
    };
  }, [formik.values.crop]);

  const [sensorList, setSensorList] = useState([] as string[]);
  useEffect(() => {
    let mounted = true;
    if (formik.values.deviceType) {
      getSensors(formik.values.deviceType).then((items) => {
        if (mounted) {
          setSensorList(items);
          if (firstRender.current) {
            firstRender.current = false;
            if (!formik.values.sensors.length) {
              formik.setFieldValue("sensors", items);
            }
          } else {
            formik.setFieldValue("sensors", items);
          }
        }
      });
    }
    return () => {
      mounted = false;
    };
  }, [formik.values.deviceType]);

  const [showMasterPlotModal, setShowMasterPlotModal] = useState(false);

  const [showTransferToFarmModal, setShowTransferToFarmModal] = useState(false);

  const [showPubliclyAccessibleModal, setshowPubliclyAccessibleModal] =
    useState(false);

  const [selectedImage, setSelectedImage] = useState("");

  const [showImageModal, setShowImageModal] = useState(false);

  const [showSelectSoilTypeModal, setShowSelectSoilTypeModal] = useState(false);

  const [showSelectUserModal, setShowSelectUserModal] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const [
    showSelectInventoryLocationModal,
    setShowSelectInventoryLocationModal,
  ] = useState(false);

  const [showSelectInventoryItemModal, setShowSelectInventoryItemModal] =
    useState(false);

  const [showSelectServicerModal, setShowSelectServicerModal] = useState(false);

  const [showMapModal, setShowMapModal] = useState<boolean>(false);

  const [showQRScannerModal, setShowQRScannerModal] = useState<boolean>(false);

  function setFarmUserId(item: any) {
    formik.setFieldValue("farmUserId", item.farmUserId);
    formik.setFieldValue("farmId", "");
    formik.setFieldValue("orgId",item.orgId || "");
    setShowSelectUserModal(false);
  }

  async function setFromInventoryLocationId(item: any) {
    formik.setFieldValue("inventoryLocation", item);
    formik.setFieldValue("tags",[item.name]);
    formik.setFieldValue("inventoryItem", { name: "" as string, id: "" as string });
    formData.inventoryLocation = item;
    const itemIds = Object.keys(item.stock).filter(k => item.stock[k]>0);
    const inventoryItems = await getInventoryItemsOfLocation(itemIds);
    setInventoryItems(inventoryItems.map((i:any) => {
      return {
        id: i.id,
        name: `${i.name} (${item.stock[i.id]})`
      } 
    }));
    setShowSelectInventoryLocationModal(false);
  }

  async function setFromInventoryItemId(item: any) {
    formik.setFieldValue("inventoryItem", item);
    const stock = await getInventoryLocationById(
      formik.values.inventoryLocation.id
    );
    if (stock && stock![0].stock[item.id] !== undefined) {
      formik.setFieldValue("inventoryStock", stock![0].stock[item.id]);
    } else {
      formik.setFieldError("inventoryStock", "Item Not Available");
    }
    setShowSelectInventoryItemModal(false);
  }

  function setServicerId(item: any) {
    formik.setFieldValue("servicedBy", item.farmUserId);
    setShowSelectServicerModal(false);
  }

  function setIMEI(item: any) {
    formik.setFieldValue("deviceId", item.deviceId);
    formik.setFieldValue("imei", item.imei);
  }

  function setSoilType(item: any) {
    formik.setFieldValue("soilTypeId", item.soilTypeId);
    setShowSelectSoilTypeModal(false);
  }

  function setMasterPlot(value: string) {
    formik.setFieldValue("parentId", value);
    setShowMasterPlotModal(false);
  }

  function selectDeviceType(e: CustomEvent) {
    formik.setFieldValue("deviceType", e.detail.value);
    if ("IRRIGATION_UNIT" === e.detail.value) {
      formik.setFieldValue("dataInterval", 3300);
    } else {
      formik.setFieldValue("dataInterval", 900);
    }
  }

  function selectSensors(e: CustomEvent) {
    formik.setFieldValue("sensors", e.detail.value);
  }

  function selectHiddenSensors(e: CustomEvent) {
    formik.setFieldValue("hiddenSensors", e.detail.value);
  }

  function selectAssignMasterPlot(e: CustomEvent) {
    formik.setFieldValue("assignMasterPlot", e.detail.checked);
    setShowMasterPlotModal(e.detail.checked);
    if (!e.detail.checked) {
      formik.setFieldValue("parentId", "");
    }
  }

  function selectpubliclyAccessible(e: CustomEvent) {
    if (!e.detail.checked) {
      formik.setFieldValue("publiclyAccessible", false);
    } else {
      formik.setFieldValue("publiclyAccessible", true);
    }
    setshowPubliclyAccessibleModal(true);
  }

  function selectCropId(e: CustomEvent) {
    formik.setFieldValue("crop", e.detail.value);
    formik.setFieldValue("rootStock", "");
  }

  const [showSelectTagsModal, setShowSelectTagsModal] = useState(false);

  async function addTag(item: any) {
    formik.values.tags.push(item.id);
    setShowSelectTagsModal(false);
  }

  async function removeTag(itemIndex: number) {
    const removedRole = formik.values.tags.splice(itemIndex, 1);
    formik.setFieldValue("tags", [...formik.values.tags]);
  }

  const compareWith = (o1: any, o2: any) => {
    if (Array.isArray(o2)) {
      return o2.indexOf(o1) !== -1;
    }
    return o1 === o2;
  };

  const transform = (values: any) => {
    const output: any = Object.assign({}, values);

    output.cropId = output.crop;
    output.crop = cropTypes.find(
      (item: any) => item.cropId === output.cropId
    )?.cropName;

    const cleanOutput = Object.entries(output).reduce(
      (a: any, [k, v]) => (v === "" ? a : ((a[k] = v), a)),
      {}
    );
    delete cleanOutput.inventoryLocation;
    delete cleanOutput.inventoryItem;
    delete cleanOutput.inventoryStock;
    delete cleanOutput.assignMasterPlot;
    delete cleanOutput.imei;
    delete values.edit;

    return cleanOutput;
  };

  const deletePlot = async (values: any) => {
    deletePlotById(values.plotId).then((response) => {
      setOnSuccess(response.msg);
      navigateTo("/tabs/plots", { refreshList: true });
    });
  };

  const onChangeToggleButton = async () => {
    const newToggleValue = !isToggled;

    try {
      setIsToggled(newToggleValue);
      if (newToggleValue) {
        await assignIMEI(plot.device.imei);
      } else {
        setShowModal(true);
      }
    } catch (error) {
      setIsToggled((prev) => !prev);
    }
  };

  const savePlot = async (values: any) => {
    values = transform(values);

    // Ensuring orgId is present
    if (!values.orgId) {
      values.orgId = formik.values.orgId;
    }

    try {
      if (!plot) {
        const response = await registerPlot(values).then(handleErrors);
        setOnSuccess(response.message);

        try {
          if (formik.values.inventoryLocation && formik.values.inventoryItem) {
            if(formik.values.saleType === 'rental'){
              await deductInventoryRental({
                inventoryLocation: formik.values.inventoryLocation,
                inventoryItem: formik.values.inventoryItem,
                count: 1,
                farmUserId: formik.values.farmUserId,
                plotId: formik.values.plotId,
                imei:formik.values.imei
              }).then(handleErrors);
            } else {
              await deductInventory({
                inventoryLocation: formik.values.inventoryLocation,
                inventoryItem: formik.values.inventoryItem,
                count: 1,
                farmUserId: formik.values.farmUserId,
                plotId: formik.values.plotId,
                imei:formik.values.imei
              }).then(handleErrors);
            }
          }
        } catch (err) {
          console.log(err);
        }

        navigateTo("/tabs/voucher", {
          farmUserId: values.farmUserId,
          farmId: values.farmId,
          plotId: response.plotId,
        });
      } else {
        const updatedObj: any = Object.assign({}, plot, values);

        if (plot && (plot as any).parentId && !values.parentId) {
          updatedObj.parentId = "";
        }
        const response = await updatePlot(updatedObj).then(handleErrors);

        formik.values.edit = true;
        setOnSuccess("Plot details updated");
      }
    } catch (err: any) {
      setOnError(err);
    }
  };

  const findSoilNameUsingId = (soilId: any) => {
    const reqSoilObj = soilTypes.filter(
      (soil: { soilTypeId: any }) => soil.soilTypeId == soilId
    );
    return reqSoilObj[0]?.name;
  };

  async function savepubliclyAccessible(val: boolean) {
    try {
      if (plot) {
        const response = await postpubliclyAccessible(
          formik.values.plotId,
          val
        ).then(handleErrors);
        setOnSuccess("Updated");
      }
    } catch (err: any) {
      setOnError(err);
    }
  }

  async function uploadImage() {
    try {
      const base64Image = await takePicture();
      if (base64Image) {
        const imageFile = await dataUrlToFile(base64Image);
        const uploadResponse = await uploadFile(
          formik.values.plotId,
          "support",
          imageFile
        );
        const responseBody = await uploadResponse.json();
        formik.setFieldValue("attachments.images", [
          ...formik.values.attachments.images,
          responseBody.images[0],
        ]);
        formik.setFieldValue("attachments.thumbnails", [
          ...formik.values.attachments.thumbnails,
          responseBody.thumbnails[0],
        ]);
      }
    } catch (err) {
      setOnError("Error occured while uploading image");
    }
  }

  function deleteImage(url: string) {
    const imageIndex = formik.values.attachments.images.findIndex(
      (item) => item === url
    );
    if (imageIndex > -1) {
      formik.values.attachments.images.splice(imageIndex, 1);
      formik.values.attachments.thumbnails.splice(imageIndex, 1);
    }
    formik.setFieldValue("attachments.images", [
      ...formik.values.attachments.images,
    ]);
    formik.setFieldValue("attachments.thumbnails", [
      ...formik.values.attachments.thumbnails,
    ]);
  }

  /**
   * @function handles plots location change on map
   */
  async function onPlotsLocationChange(payload: any) {
    let { location } = payload;
    location.lng = location.lng.toFixed(6).toString();
    location.lat = location.lat.toFixed(6).toString();
    formik.setFieldValue("location", location);

    if (location.lat && location.lng) {
      getReverseGecodingAddress(
        location.lat,
        location.lng
      ).then((resp) => {
        formik.setFieldValue(
          "place",
          resp?.address?.village ||
            resp?.address?.neighbourhood ||
            resp?.address?.suburb ||
            ""
        );
        formik.setFieldValue("taluka", resp?.address?.county || "");
        formik.setFieldValue("district", resp?.address?.state_district || "");
      });
      getElevation(location.lat, location.lng)
            .then(resp => {
                formik.setFieldValue('altitude', resp?.results ? resp.results[0]?.elevation || '' : '');
            });
    }

    if (formik.values.edit) {
      formik.handleSubmit();
    }
    closeMap();
  }

  /**
   * @function handles plots geoBoundary change on map
   */
  async function onPlotsGeoBoundaryChange(payload: any) {
    const { geoBoundary } = payload;
    formik.setFieldValue("geoBoundary", geoBoundary);
    if (formik.values.edit) {
      formik.handleSubmit();
    }
    closeMap();
  }

  /**
   * @functions to handle maps visibility
   */
  function openMap(): void {
    setShowMapModal(true);
  }

  function closeMap(): void {
    setShowMapModal(false);
  }

    /**
   * @functions to handle qr scanner visibility
   */

    const [enteredIMEI, setEnteredIMEI] = useState("");

    const handleIMEIChange = (e: any) => {
      const { value } = e.target;
      setEnteredIMEI(value);
    };
  
    function closeQRScanner(): void {
      setShowQRScannerModal(false);
      setEnteredIMEI("");
    }

    function validateIMEI(imei: string): void {
      if (!/^\d{10,20}$/.test(imei)) {
        setOnError("Invalid IMEI format");
        return;
      }
      getAvailableDevicesPaginated(imei)
      .then(data => {
        if(data.length) {
          setIMEI(data[0]);
          closeQRScanner();
        } else {
          setOnError("IMEI not available");
        }
      })
      .catch(err => {
        setOnError("IMEI not available");
      });
    }

  function generateId() {
    if (!formik.values.edit) {
      generateUniqueId(formik.values.name, searchPlot, (id: string) =>
        formik.setFieldValue("plotId", id)
      );
    }
  }

  const isDataReady = !loading && !loadingOrganizations;

  const [formData, setFormData] = useState({
    uninstallationDate: new Date(),
    uninstallationReason: "",
    inventoryLocation: {} as any
  });

  const handleUnassignConfirm = async () => {
    const updatedObj = {
      plotId: plot.plotId, 
      uninstallationDate: formData.uninstallationDate
        ? new Date(formData.uninstallationDate)
        : undefined,
      uninstallationReason: formData.uninstallationReason,
    };
  
    try {
      const updateResponse = await updateAssignOfPlot(updatedObj);
  
      if (updateResponse && updateResponse.ok) {
        try {
          await unassignIMEI(plot.device.imei);
          if(plot.saleType === 'rental' && formData.inventoryLocation.id) {
            const items = await getInventoryItemsPaginated();
            let item;
            if(plot.deviceType === 'KAIRO_UNIT') {
              item = items.find((i:any) => i.name.includes('kairo_rental'));
            } else if(plot.deviceType === 'NERO_UNIT' && plot.sensors.length == 2) {
              item = items.find((i:any) => i.name.includes('nero_single_rental'));
            } else {
              item = items.find((i:any) => i.name.includes('nero_double_rental'));
            } 
            await addToRentalInventory({
              inventoryLocation: formData.inventoryLocation,
              inventoryItem: item,
              count: 1,
              farmUserId: formik.values.farmUserId,
              plotId: formik.values.plotId,
              imei: plot.device.imei
            }).then(handleErrors);
          }
          setShowModal(false);
        } catch (imeiError) {
          setIsToggled((prev) => !prev);
          setShowModal(false);
        }
      } else {
        setIsToggled((prev) => !prev);
        setShowModal(false);
      }

 
    } catch (error) {
      setIsToggled((prev) => !prev);
      setShowModal(false);
    }
  };
  

  const handleCancel = () => {
    setShowModal(false);
    setIsToggled((prev) => !prev);
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "uninstallationDate" ? new Date(value) : value,
    }));
  };

  return (
    <>
      {loading && <LoadingSkeleton />}
      {!loading && (
        <form onSubmit={formik.handleSubmit}>
          <IonList>
            <IonItem>
              <IonLabel position="stacked">User Id</IonLabel>
              <IonInput
                readonly
                disabled={prefilled}
                id="farmUserId"
                name="farmUserId"
                value={formik.values.farmUserId}
                placeholder="Select User Id"
                onClick={(e) => setShowSelectUserModal(true)}
              ></IonInput>
            </IonItem>
            {formik.touched.farmUserId && formik.errors.farmUserId ? (
              <div className="errorMsg">{formik.errors.farmUserId}</div>
            ) : null}
            <IonItem>
              <IonLabel position="stacked">Farm Id</IonLabel>
              <IonInput
                readonly
                disabled={prefilled}
                id="farmId"
                name="farmId"
                value={formik.values.farmId}
                placeholder="Select Farm Id"
                onClick={(e) => setFarmPickerIsOpen(true)}
              ></IonInput>
            </IonItem>
            {formik.touched.farmId && formik.errors.farmId ? (
              <div className="errorMsg">{formik.errors.farmId}</div>
            ) : null}
            <IonItem>
              <IonLabel position="stacked">Plot Name</IonLabel>
              <IonInput
                id="name"
                name="name"
                value={formik.values.name}
                onBlur={() => generateId()}
                placeholder="Plot Name"
                onIonChange={formik.handleChange}
              ></IonInput>
            </IonItem>
            {formik.touched.name && formik.errors.name ? (
              <div className="errorMsg">{formik.errors.name}</div>
            ) : null}
            <IonItem>
              <IonLabel position="stacked">Plot Id</IonLabel>
              <IonInput
                id="plotId"
                name="plotId"
                disabled={formik.values.plotId != null}
                value={formik.values.plotId}
                placeholder="Plot Id"
                onIonInput={toInputLowercase}
                onIonChange={formik.handleChange}
              ></IonInput>
            </IonItem>
            {formik.touched.plotId && formik.errors.plotId ? (
              <div className="errorMsg">{formik.errors.plotId}</div>
            ) : null}
            {!plot && <IonItem>
              <IonLabel position="stacked">Map</IonLabel>
              <IonGrid className="map-viewer-container">
                <IonRow>
                  <IonCol className="map-viewer">
                    <Map
                      lat={Number(formik.values.location.lat) ?? 0}
                      lng={Number(formik.values.location.lng) ?? 0}
                      onPlotsLocationChange={onPlotsLocationChange}
                      onPlotsGeoBoundaryChange={onPlotsGeoBoundaryChange}
                      zoom={!!Number(formik.values.location.lat) ? 18 : 6}
                      geoBoundary={formik.values.geoBoundary?.coordinates[0]}
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonItem>}
            <IonItem>
              <IonLabel position="stacked">Latitude</IonLabel>
              <IonInput
                id="location.lat"
                name="location.lat"
                inputmode="decimal"
                value={formik.values.location.lat}
                placeholder="Latitude"
                onIonChange={formik.handleChange}
              ></IonInput>
              <IonIcon
                icon={reload}
                slot="end"
                onClick={(e) => setInitialLatLng()}
              />
            </IonItem>
            {formik.touched.location?.lat && formik.errors.location?.lat ? (
              <div className="errorMsg">{formik.errors.location.lat}</div>
            ) : null}
            <IonItem>
              <IonLabel position="stacked">Longitude</IonLabel>
              <IonInput
                id="location.lng"
                name="location.lng"
                inputmode="decimal"
                value={formik.values.location.lng}
                placeholder="Longitude"
                onIonChange={formik.handleChange}
              ></IonInput>
            </IonItem>
            {formik.touched.location?.lng && formik.errors.location?.lng ? (
              <div className="errorMsg">{formik.errors.location.lng}</div>
            ) : null}
            <IonItem>
              <IonLabel position="stacked">Altitude</IonLabel>
              <IonInput id="altitude" name="altitude" inputmode="numeric" value={formik.values.altitude} placeholder="Altitude" onIonChange={formik.handleChange}></IonInput>
            </IonItem>
            {formik.touched.altitude && formik.errors.altitude ? (<div className="errorMsg">{formik.errors.altitude}</div>) : null}
            <IonItem>
              <IonLabel position="stacked">Place</IonLabel>
              <IonInput
                id="place"
                name="place"
                value={formik.values.place}
                placeholder="Place"
                onIonChange={formik.handleChange}
              ></IonInput>
            </IonItem>
            {formik.touched.place && formik.errors.place ? (
              <div className="errorMsg">{formik.errors.place}</div>
            ) : null}
            <IonItem>
              <IonLabel position="stacked">Taluka</IonLabel>
              <IonInput
                id="taluka"
                name="taluka"
                value={formik.values.taluka}
                placeholder="Taluka"
                onIonChange={formik.handleChange}
              ></IonInput>
            </IonItem>
            {formik.touched.taluka && formik.errors.taluka ? (
              <div className="errorMsg">{formik.errors.taluka}</div>
            ) : null}
            <IonItem>
              <IonLabel position="stacked">District</IonLabel>
              <IonInput
                id="district"
                name="district"
                value={formik.values.district}
                placeholder="District"
                onIonChange={formik.handleChange}
              ></IonInput>
            </IonItem>
            {formik.touched.district && formik.errors.district ? (
              <div className="errorMsg">{formik.errors.district}</div>
            ) : null}
            <IonItem>
              <IonLabel position="stacked">Area</IonLabel>
              <IonInput
                id="area"
                name="area"
                inputmode="numeric"
                value={formik.values.area}
                placeholder="Area (In Acres)"
                onIonChange={formik.handleChange}
              ></IonInput>
            </IonItem>
            {formik.touched.area && formik.errors.area ? (
              <div className="errorMsg">{formik.errors.area}</div>
            ) : null}
            <IonItem>
              <IonLabel position="stacked">Soil Type</IonLabel>
              <IonInput
                readonly
                id="soilTypeId"
                name="soilTypeId"
                value={findSoilNameUsingId(formik.values.soilTypeId)}
                placeholder="Select Soil Type"
                onClick={(e) => setShowSelectSoilTypeModal(true)}
              ></IonInput>
            </IonItem>
            {formik.touched.soilTypeId && formik.errors.soilTypeId ? (
              <div className="errorMsg">{formik.errors.soilTypeId}</div>
            ) : null}
            <IonItem>
              <IonLabel position="stacked">Plot Type</IonLabel>
              <IonSelect
                id="plotType"
                name="plotType"
                compareWith={compareWith}
                value={formik.values.plotType}
                placeholder="Select Plot Type"
                onIonChange={formik.handleChange}
              >
                {plotTypes &&
                  Object.keys(plotTypes).map((key) => {
                    return (
                      <IonSelectOption key={key} value={key}>
                        {plotTypes[key]}
                      </IonSelectOption>
                    );
                  })}
              </IonSelect>
            </IonItem>
            {formik.touched.plotType && formik.errors.plotType ? (
              <div className="errorMsg">{formik.errors.plotType}</div>
            ) : null}
            <IonItem>
              <IonLabel position="stacked">Crop</IonLabel>
              <IonSelect
                id="crop"
                name="crop"
                disabled={!!plot}
                compareWith={compareWith}
                value={formik.values.crop}
                placeholder="Select Crop"
                onIonChange={selectCropId}
              >
                {cropTypes &&
                  cropTypes.map((item: any) => {
                    return (
                      <IonSelectOption key={item.cropId} value={item.cropId}>
                        {item.cropName}
                      </IonSelectOption>
                    );
                  })}
              </IonSelect>
            </IonItem>
            {formik.touched.crop && formik.errors.crop ? (
              <div className="errorMsg">{formik.errors.crop}</div>
            ) : null}
            {!!cropVarieties?.length && (
              <IonItem>
                <IonLabel position="stacked">Crop Variety</IonLabel>
                <IonSelect
                  id="varietyId"
                  name="varietyId"
                  disabled={!!plot}
                  compareWith={compareWith}
                  value={formik.values.varietyId}
                  placeholder="Select Crop Variety"
                  onIonChange={formik.handleChange}
                >
                  {cropVarieties &&
                    cropVarieties.map((item) => {
                      return (
                        <IonSelectOption key={item.id} value={item.id}>
                          {item.name}
                        </IonSelectOption>
                      );
                    })}
                </IonSelect>
              </IonItem>
            )}
            {formik.touched.varietyId && formik.errors.varietyId ? (
              <div className="errorMsg">{formik.errors.varietyId}</div>
            ) : null}
            {!!rootStockTypes.length && (
              <IonItem>
                <IonLabel position="stacked">Root Stock</IonLabel>
                <IonSelect
                  id="rootStock"
                  name="rootStock"
                  compareWith={compareWith}
                  value={formik.values.rootStock}
                  placeholder="Select Root Stock"
                  onIonChange={formik.handleChange}
                >
                  {rootStockTypes &&
                    rootStockTypes.map((item) => {
                      return (
                        <IonSelectOption
                          key={item.rootStockId}
                          value={item.rootStockId}
                        >
                          {item.rootStockName}
                        </IonSelectOption>
                      );
                    })}
                </IonSelect>
              </IonItem>
            )}
            {formik.touched.rootStock && formik.errors.rootStock ? (
              <div className="errorMsg">{formik.errors.rootStock}</div>
            ) : null}
            <IonItem>
              <IonLabel position="stacked">
                Current Season Sowing/Pruning Date
              </IonLabel>
              <IonDatetime
                id="sowingDate"
                name="sowingDate"
                displayFormat="DD/MM/YYYY"
                placeholder="Current Season Sowing/Pruning Date"
                value={formik.values.sowingDate}
                onIonChange={formik.handleChange}
              ></IonDatetime>
            </IonItem>
            {formik.touched.sowingDate && formik.errors.sowingDate ? (
              <div className="errorMsg">{formik.errors.sowingDate}</div>
            ) : null}

            <IonItem>
              <IonLabel position="stacked">IMEI</IonLabel>
              <IonInput
                readonly
                id="imei"
                name="imei"
                value={isToggled || !showToggle ? formik.values.imei : ""} // Set to empty if plot is not present
                placeholder={
                  !plot || !plot?.device
                    ? "Select IMEI"
                    : isToggled
                      ? "Select IMEI"
                      : "Unassigned"
                }
                onClick={(e) => setShowQRScannerModal(true)}
              ></IonInput>

              {/* Toggle switch at the right end */}
              <IonToggle
                slot="end"
                checked={isToggled}
                onIonChange={onChangeToggleButton}
                disabled={!showToggle}
              />
            </IonItem>
            {formik.touched.deviceId && formik.errors.deviceId ? (
              <div className="errorMsg">{formik.errors.deviceId}</div>
            ) : null}
            <IonItem>
              <IonLabel position="stacked">Sale Type</IonLabel>
              <IonSelect
                id="saleType"
                name="saleType"
                compareWith={compareWith}
                value={formik.values.saleType}
                placeholder="Select Sale Type"
                onIonChange={formik.handleChange}
              >
                {['sold', 'rental'].map((item) => {
                    return (
                      <IonSelectOption
                        key={item}
                        value={item}
                      >
                        {item.toLowerCase().replace(/^\w/, c => c.toUpperCase())}
                      </IonSelectOption>
                    );
                  })}
              </IonSelect>
            </IonItem>
            {formik.touched.saleType && formik.errors.saleType ? (
              <div className="errorMsg">{formik.errors.saleType}</div>
            ) : null}
            {!plot && (
              <IonItem>
                <IonLabel position="stacked">Inventory Location</IonLabel>
                <IonInput
                  readonly
                  id="inventoryLocationId"
                  name="inventoryLocationId"
                  value={formik.values.inventoryLocation?.name}
                  placeholder="From Inventory Location"
                  onClick={(e) => setShowSelectInventoryLocationModal(true)}
                ></IonInput>
              </IonItem>
            )}
            {formik.touched.inventoryLocation?.name &&
            formik.errors.inventoryLocation?.name ? (
              <div className="errorMsg">
                {formik.errors.inventoryLocation?.name}
              </div>
            ) : null}
            {!plot &&  (
              <IonItem>
                <IonLabel position="stacked">Inventory Item</IonLabel>
                <IonInput
                  disabled={!formik.values.inventoryLocation?.name}
                  readonly
                  id="inventoryItemId"
                  name="inventoryItemId"
                  value={formik.values.inventoryItem?.name}
                  placeholder="Inventory Item"
                  onClick={(e) => setShowSelectInventoryItemModal(true)}
                ></IonInput>
              </IonItem>
            )}
            {formik.touched.inventoryItem?.name &&
            formik.errors.inventoryItem?.name ? (
              <div className="errorMsg">
                {formik.errors.inventoryItem?.name}
              </div>
            ) : null}
            {formik.touched.inventoryStock && formik.errors.inventoryStock ? (
              <div className="errorMsg">{formik.errors.inventoryStock}</div>
            ) : null}
            <IonItem>
              <IonLabel position="stacked">Irrigation Method</IonLabel>
              <IonSelect
                id="irrigationMethod"
                name="irrigationMethod"
                compareWith={compareWith}
                value={formik.values.irrigationMethod}
                placeholder="Select Irrigation Method"
                onIonChange={formik.handleChange}
              >
                {['drip', 'sprinkler', 'flood'].map((item) => {
                    return (
                      <IonSelectOption
                        key={item}
                        value={item}
                      >
                        {item.toLowerCase().replace(/^\w/, c => c.toUpperCase())}
                      </IonSelectOption>
                    );
                  })}
              </IonSelect>
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Soil Moisture 1 Depth</IonLabel>
              <IonInput
                id="soilMoisture1Depth"
                name="soilMoisture1Depth"
                inputmode="numeric"
                value={formik.values.soilMoisture1Depth}
                placeholder="Soil Moisture 1 Depth (In Inches)"
                onIonChange={formik.handleChange}
              ></IonInput>
            </IonItem>
            {formik.touched.soilMoisture1Depth &&
            formik.errors.soilMoisture1Depth ? (
              <div className="errorMsg">{formik.errors.soilMoisture1Depth}</div>
            ) : null}
            <IonItem>
              <IonLabel position="stacked">Soil Moisture 2 Depth</IonLabel>
              <IonInput
                id="soilMoisture2Depth"
                name="soilMoisture2Depth"
                inputmode="numeric"
                value={formik.values.soilMoisture2Depth}
                placeholder="Soil Moisture 2 Depth (In Inches)"
                onIonChange={formik.handleChange}
              ></IonInput>
            </IonItem>
            {formik.touched.soilMoisture2Depth &&
            formik.errors.soilMoisture2Depth ? (
              <div className="errorMsg">{formik.errors.soilMoisture2Depth}</div>
            ) : null}
            <IonItem>
              <IonLabel position="stacked">Row Distance</IonLabel>
              <IonInput
                id="rowDistance"
                name="rowDistance"
                inputmode="numeric"
                value={formik.values.rowDistance}
                placeholder="Row Distance (In Feet)"
                onIonChange={formik.handleChange}
              ></IonInput>
            </IonItem>
            {formik.touched.rowDistance && formik.errors.rowDistance ? (
              <div className="errorMsg">{formik.errors.rowDistance}</div>
            ) : null}
            <IonItem>
              <IonLabel position="stacked">Plant Distance</IonLabel>
              <IonInput
                id="plantDistance"
                name="plantDistance"
                inputmode="numeric"
                value={formik.values.plantDistance}
                placeholder="Plant Distance (In Feet)"
                onIonChange={formik.handleChange}
              ></IonInput>
            </IonItem>
            {formik.touched.plantDistance && formik.errors.plantDistance ? (
              <div className="errorMsg">{formik.errors.plantDistance}</div>
            ) : null}
            <IonItem>
              <IonLabel position="stacked">Dripper per plant</IonLabel>
              <IonInput
                id="perPlantDripper"
                name="perPlantDripper"
                inputmode="numeric"
                value={formik.values.perPlantDripper}
                placeholder="Dripper per plant"
                onIonChange={formik.handleChange}
              ></IonInput>
            </IonItem>
            {formik.touched.perPlantDripper && formik.errors.perPlantDripper ? (
              <div className="errorMsg">{formik.errors.perPlantDripper}</div>
            ) : null}
            <IonItem>
              <IonLabel position="stacked">Dripper Distance</IonLabel>
              <IonInput
                id="dripperDistance"
                name="dripperDistance"
                inputmode="numeric"
                value={formik.values.dripperDistance}
                placeholder="Dripper Distance (In Feet)"
                onIonChange={formik.handleChange}
              ></IonInput>
            </IonItem>
            {formik.touched.dripperDistance && formik.errors.dripperDistance ? (
              <div className="errorMsg">{formik.errors.dripperDistance}</div>
            ) : null}
            <IonItem>
              <IonLabel position="stacked">Dripper/Sprinkler discharge</IonLabel>
              <IonInput
                id="dripperDischarge"
                name="dripperDischarge"
                inputmode="numeric"
                value={formik.values.dripperDischarge}
                placeholder="Dripper/Sprinkler discharge (In Litre/hour)"
                onIonChange={formik.handleChange}
              ></IonInput>
            </IonItem>
            {formik.touched.dripperDischarge &&
            formik.errors.dripperDischarge ? (
              <div className="errorMsg">{formik.errors.dripperDischarge}</div>
            ) : null}
            <IonItem>
              <IonLabel position="stacked">Root width</IonLabel>
              <IonInput
                id="rootWidth"
                name="rootWidth"
                inputmode="numeric"
                value={formik.values.rootWidth}
                placeholder="Root width (In inches)"
                onIonChange={formik.handleChange}
              ></IonInput>
            </IonItem>
            {formik.touched.rootWidth && formik.errors.rootWidth ? (
              <div className="errorMsg">{formik.errors.rootWidth}</div>
            ) : null}
            <IonItem>
              <IonLabel position="stacked">Root depth</IonLabel>
              <IonInput
                id="rootDepth"
                name="rootDepth"
                inputmode="numeric"
                value={formik.values.rootDepth}
                placeholder="Root depth (In inches)"
                onIonChange={formik.handleChange}
              ></IonInput>
            </IonItem>
            {formik.touched.rootDepth && formik.errors.rootDepth ? (
              <div className="errorMsg">{formik.errors.rootDepth}</div>
            ) : null}
            <IonItem>
              <IonLabel position="stacked">Soil retention</IonLabel>
              <IonInput
                id="soilRetention"
                name="soilRetention"
                inputmode="numeric"
                value={formik.values.soilRetention}
                placeholder="Soil retention (In mm)"
                onIonChange={formik.handleChange}
              ></IonInput>
            </IonItem>
            {formik.touched.soilRetention && formik.errors.soilRetention ? (
              <div className="errorMsg">{formik.errors.soilRetention}</div>
            ) : null}
            <IonItem>
              <IonLabel position="stacked">Plantation Year</IonLabel>
              <IonDatetime
                id="plantationYear"
                name="plantationYear"
                display-timezone="utc"
                displayFormat="MMM YYYY"
                pickerFormat="MMM YYYY"
                placeholder="Plantation Year"
                value={formik.values.plantationYear}
                onIonChange={formik.handleChange}
              ></IonDatetime>
            </IonItem>
            {formik.touched.plantationYear && formik.errors.plantationYear ? (
              <div className="errorMsg">{formik.errors.plantationYear}</div>
            ) : null}
            <IonItem>
              <IonLabel position="stacked">Device Type</IonLabel>
              <IonSelect
                id="deviceType"
                name="deviceType"
                compareWith={compareWith}
                value={formik.values.deviceType}
                placeholder="Select Device Type"
                onIonChange={selectDeviceType}
              >
                {deviceTypes &&
                  deviceTypes.map((item: any) => {
                    return (
                      <IonSelectOption
                        key={item.deviceTypeId}
                        value={item.deviceTypeId}
                      >
                        {item.descriptiveName}
                      </IonSelectOption>
                    );
                  })}
              </IonSelect>
            </IonItem>
            {formik.touched.deviceType && formik.errors.deviceType ? (
              <div className="errorMsg">{formik.errors.deviceType}</div>
            ) : null}
            <IonItem>
              <IonLabel position="stacked">
                Sensors ({formik.values.sensors?.length})
              </IonLabel>
              <IonSelect
                id="sensors"
                name="sensors"
                compareWith={compareWith}
                multiple={true}
                value={formik.values.sensors}
                placeholder="Select Sensors"
                onIonChange={selectSensors}
              >
                {sensorList &&
                  sensorList.map((item) => {
                    return (
                      <IonSelectOption key={item} value={item}>
                        {item}
                      </IonSelectOption>
                    );
                  })}
              </IonSelect>
            </IonItem>
            {formik.touched.sensors && formik.errors.sensors ? (
              <div className="errorMsg">{formik.errors.sensors}</div>
            ) : null}
            <IonItem>
              <IonLabel position="stacked">Data Interval</IonLabel>
              <IonInput
                id="dataInterval"
                name="dataInterval"
                type="number"
                inputmode="numeric"
                value={formik.values.dataInterval}
                placeholder="Data Interval (In Seconds)"
                onIonChange={formik.handleChange}
              ></IonInput>
            </IonItem>
            {formik.touched.dataInterval && formik.errors.dataInterval ? (
              <div className="errorMsg">{formik.errors.dataInterval}</div>
            ) : null}
            <IonItem>
              <IonLabel>Assign master plot</IonLabel>
              <IonToggle
                id="assignMasterPlot"
                name="assignMasterPlot"
                checked={formik.values.assignMasterPlot}
                onIonChange={selectAssignMasterPlot}
              />
            </IonItem>
            {formik.touched.assignMasterPlot &&
            formik.errors.assignMasterPlot ? (
              <div className="errorMsg">{formik.errors.assignMasterPlot}</div>
            ) : null}
            {/* {plot && (
              <>
                <IonItem>
                  <IonLabel>Publicly Accessible</IonLabel>
                  <IonToggle
                    id="publiclyAccessible"
                    name="publiclyAccessible"
                    checked={formik.values.publiclyAccessible}
                    onIonChange={selectpubliclyAccessible}
                  />
                </IonItem>
                {formik.touched.publiclyAccessible &&
                formik.errors.publiclyAccessible ? (
                  <div className="errorMsg">
                    {formik.errors.publiclyAccessible}
                  </div>
                ) : null}
              </>
            )} */}
            {formik.values.parentId && (
              <IonItem>
                <IonLabel position="stacked">Master Plot</IonLabel>
                <IonInput
                  readonly
                  id="parentId"
                  name="parentId"
                  value={formik.values.parentId}
                ></IonInput>
              </IonItem>
            )}
            {formik.values.parentId && (
              <IonItem>
                <IonLabel position="stacked">
                  Hidden Sensors ({formik.values.hiddenSensors.length})
                </IonLabel>
                <IonSelect
                  id="hiddenSensors"
                  name="hiddenSensors"
                  compareWith={compareWith}
                  multiple={true}
                  value={formik.values.hiddenSensors}
                  placeholder="Select Hidden Sensors"
                  onIonChange={selectHiddenSensors}
                >
                  {sensorList &&
                    sensorList.map((item) => {
                      return (
                        <IonSelectOption key={item} value={item}>
                          {item}
                        </IonSelectOption>
                      );
                    })}
                </IonSelect>
              </IonItem>
            )}
            <IonItem>
              <IonLabel position="stacked">Serviced By</IonLabel>
              <IonInput
                readonly
                id="servicedBy"
                name="servicedBy"
                value={formik.values.servicedBy}
                placeholder="Select Servicer"
                onClick={(e) => setShowSelectServicerModal(true)}
              ></IonInput>
            </IonItem>
            {formik.touched.servicedBy && formik.errors.servicedBy ? (
              <div className="errorMsg">{formik.errors.servicedBy}</div>
            ) : null}
            <IonItem>
              <IonLabel position="stacked">Organization</IonLabel>
              {isDataReady ? (
                <IonSelect
                  id="orgId"
                  name="orgId"
                  value={formik.values.orgId}
                  placeholder="Select Organization"
                  onIonChange={(e) => {
                    formik.setFieldValue("orgId", e.detail.value);
                  }}
                >
                  <IonSelectOption value="">
                    Select Organization
                  </IonSelectOption>
                  {organizations?.map((org) => (
                    <IonSelectOption key={org.id} value={org.id}>
                      {org.name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              ) : (
                <IonSpinner name="bubbles" />
              )}
            </IonItem>

            {/* Tags */}
            <IonItem lines="none">
              <IonLabel>Tags</IonLabel>
              <IonIcon
                icon={addCircle}
                slot="end"
                onClick={(e) => setShowSelectTagsModal(true)}
              />
            </IonItem>
            <IonItem>
              <IonGrid>
                <IonRow>
                  {formik.values.tags.length > 0 &&
                    formik.values.tags.map((item, index) => (
                      <IonCol key={index}>
                        <IonChip outline={true} color="primary">
                          <IonLabel>{item}</IonLabel>
                          <IonIcon
                            icon={closeCircle}
                            onClick={(e) => removeTag(index)}
                          />
                        </IonChip>
                      </IonCol>
                    ))}
                  {formik.values.tags.length === 0 && (
                    <IonCol>
                      <IonLabel class="secondary-text">
                        Assign at least 1 tag
                      </IonLabel>
                    </IonCol>
                  )}
                </IonRow>
              </IonGrid>
            </IonItem>
            {formik.touched.tags && formik.errors.tags ? (
              <div className="errorMsg">{formik.errors.tags}</div>
            ) : null}
          </IonList>

          {plot && (
            <>
              <IonItem lines="none">
                <IonLabel>Images</IonLabel>
                <IonIcon
                  icon={addCircle}
                  slot="end"
                  onClick={(e) => uploadImage()}
                />
              </IonItem>
              <IonItem lines="none">
                <IonGrid>
                  <IonRow>
                    {formik.values.attachments?.thumbnails?.map(
                      (item: any, index: number) => (
                        <IonCol key={index}>
                          <IonThumbnail>
                            <img
                              src={item}
                              onClick={(e) => {
                                setSelectedImage(
                                  formik.values.attachments?.images[index]
                                );
                                setShowImageModal(true);
                              }}
                            />
                          </IonThumbnail>
                        </IonCol>
                      )
                    )}
                  </IonRow>
                </IonGrid>
              </IonItem>
            </>
          )}

          <IonButton expand="block" type="submit">
            {(!plot ? "Create" : "Update") + " Plot"}
          </IonButton>

          {formik.values.edit && <IonButton
            expand="block"
            onClick={() => setShowTransferToFarmModal(true)}
          >
            Transfer to Farm
          </IonButton>}

          {formik.values.edit && <IonButton
            expand="block"
            disabled={disableDeleteButton}
            onClick={() => setShowDeleteConfirmation(true)}
            color="danger"
          >
            Delete Plot
          </IonButton>}
        </form>
      )}

      <IonPicker
        isOpen={farmPickerIsOpen}
        columns={[FarmPicker]}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            handler: (value) => {
              setFarmPickerIsOpen(false);
            },
          },
          {
            text: "Confirm",
            handler: (value) => {
              formik.values.farmId = value.FarmId.value;
              setFarmPickerIsOpen(false);
            },
          },
        ]}
      ></IonPicker>

      {showSelectUserModal && (
        <IonModal isOpen={showSelectUserModal}>
          <UserList
            selectedValue={formik.values.farmUserId}
            onClickHandler={setFarmUserId}
            onClose={setShowSelectUserModal}
          />
        </IonModal>
      )}

      {showSelectInventoryLocationModal && (
        <IonModal isOpen={showSelectInventoryLocationModal}>
          <SelectItem
            label="Select Inventory"
            selectedValue={formik.values.inventoryLocation!.name}
            labelKey="name"
            valueKey="id"
            setItem={setFromInventoryLocationId}
            onClose={setShowSelectInventoryLocationModal}
            getPaginatedItems={getInventoryLocationsPaginated}
          />
        </IonModal>
      )}

      {showSelectInventoryItemModal && (
        <IonModal isOpen={showSelectInventoryItemModal}>
          <SelectItem
            label="Select Item"
            selectedValue={formik.values.inventoryItem!.name}
            labelKey="name"
            valueKey="id"
            setItem={setFromInventoryItemId}
            onClose={setShowSelectInventoryItemModal}
            items={inventoryItems}
          />
        </IonModal>
      )}

      {showSelectServicerModal && (
        <IonModal isOpen={showSelectServicerModal}>
          <UserList
            selectedValue={formik.values.servicedBy}
            onClickHandler={setServicerId}
            onClose={setShowSelectServicerModal}
          />
        </IonModal>
      )}

      {showSelectSoilTypeModal && (
        <IonModal id="soil-modal" isOpen={showSelectSoilTypeModal}>
          <SelectItem
            label="Select Soil Type"
            items={soilTypes}
            selectedValue={formik.values.soilTypeId}
            labelKey="name"
            valueKey="soilTypeId"
            displayType="card"
            setItem={setSoilType}
            onClose={setShowSelectSoilTypeModal}
          />
        </IonModal>
      )}

      {showSelectTagsModal && (
        <IonModal isOpen={showSelectTagsModal}>
          <SelectItem
            label="Select Tag"
            items={plotTags.filter(
              (item: any) =>
                !formik.values.tags.map((item) => item).includes(item.id)
            )}
            labelKey="id"
            valueKey="id"
            setItem={addTag}
            selectedValue=""
            onClose={setShowSelectTagsModal}
          />
        </IonModal>
      )}

      <IonModal isOpen={showMasterPlotModal} cssClass="my-custom-class">
        <SelectMasterPlot
          plot={plot}
          setMasterPlot={setMasterPlot}
          setShowMasterPlotModal={setShowMasterPlotModal}
        />
      </IonModal>

      <IonModal isOpen={showTransferToFarmModal} cssClass="my-custom-class">
        <TransferToFarm
         plotId={formik.values.plotId}
         onClose={setShowTransferToFarmModal}
        />
      </IonModal>

      <IonModal isOpen={showImageModal} cssClass="my-custom-class">
        <ImageViewer
          imageUrl={selectedImage}
          onDeleteHandler={deleteImage}
          onCloseHandler={setShowImageModal}
        />
      </IonModal>

      {/* Modal Component */}
      <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Uninstallation Info</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={handleCancel}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent class="ion-padding">
          <IonItem>
            <IonLabel position="floating">Uninstallation Date</IonLabel>
            <IonInput
              type="date"
              name="uninstallationDate"
              value={formData.uninstallationDate.toISOString().substring(0, 10)} // Format the date as YYYY-MM-DD
              onIonChange={handleInputChange} // Use the handleDateChange function
              placeholder="Enter Date"
            />
          </IonItem>

          <IonItem lines="full" className="textarea-item">
            <IonLabel position="floating" className="textarea-label">
            Uninstallation Reason
            </IonLabel>
            <IonTextarea
              name="uninstallationReason"
              value={formData.uninstallationReason}
              onIonChange={handleInputChange}
              placeholder="Enter Reason"
              rows={5}
              minlength={10}
              className="textarea-input"
            />
            <div className="character-counter">
              {formData.uninstallationReason.length < 10 ? (
                <>
                  <span className="error-text">
                    Minimum characters required:{" "}
                  </span>
                  {formData.uninstallationReason.length}/10
                </>
              ) : (
                `Characters entered: ${formData.uninstallationReason.length}/10`
              )}
            </div>
            <div className="progress-bar-container">
              <div
                className="progress-bar"
                style={{
                  width: `${(formData.uninstallationReason.length / 10) * 100}%`,
                  backgroundColor:
                    formData.uninstallationReason.length >= 10
                      ? "#3880ff"
                      : "red",
                }}
              ></div>
            </div>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Return to Inventory Location</IonLabel>
            <IonInput
              readonly
              id="inventoryLocationId"
              name="inventoryLocationId"
              value={formData.inventoryLocation?.name}
              placeholder="Return to Inventory Location"
              onClick={(e) => setShowSelectInventoryLocationModal(true)}
            ></IonInput>
          </IonItem>

          {/* Action Buttons */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <IonButton onClick={handleCancel}>Cancel</IonButton>
            <IonButton
              disabled={formData.uninstallationReason.length < 10}
              onClick={handleUnassignConfirm}
            >
              Confirm
            </IonButton>
          </div>
        </IonContent>
      </IonModal>

      <IonAlert
        isOpen={showPubliclyAccessibleModal}
        onDidDismiss={() => setshowPubliclyAccessibleModal(false)}
        header={"Confirm!"}
        message={"Are you sure you want to update this data?"}
        buttons={[
          {
            text: "No",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              formik.values.publiclyAccessible =
                !formik.values.publiclyAccessible;
              setshowPubliclyAccessibleModal(false);
            },
          },
          {
            text: "Yes",
            handler: () => {
              savepubliclyAccessible(formik.values.publiclyAccessible);
            },
          },
        ]}
      />

      <IonAlert
        isOpen={showConfirmation}
        onDidDismiss={() => setShowConfirmation(false)}
        header={"Confirm!"}
        message={
          "Please make sure that you have verified all the plot details. Save plot?"
        }
        buttons={[
          {
            text: "No",
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: "Yes",
            handler: () => {
              savePlot(formik.values);
            },
          },
        ]}
      />

      <IonAlert
        isOpen={showDeleteConfirmation}
        onDidDismiss={() => setShowDeleteConfirmation(false)}
        header={"Confirm!"}
        message={"Do you want to delete the plot?"}
        buttons={[
          {
            text: "No",
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: "Yes",
            handler: () => {
              deletePlot(formik.values);
            },
          },
        ]}
      />

      <IonToast
        isOpen={!!onSuccess}
        onDidDismiss={() => setOnSuccess("")}
        message={onSuccess}
        duration={2000}
        color="success"
      />

      <IonToast
        isOpen={!!onError}
        onDidDismiss={() => setOnError("")}
        message={onError}
        duration={2000}
        color="danger"
      />

      {/* map modal */}
      <IonModal isOpen={showMapModal}>
        <IonHeader>
          <IonToolbar>
            <IonTitle size="small" style={{ fontWeight: "bold" }}>
              Plot Location Viewer
            </IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={closeMap}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <Map
            lat={Number(formik.values.location.lat) ?? 0}
            lng={Number(formik.values.location.lng) ?? 0}
            onPlotsLocationChange={onPlotsLocationChange}
            onPlotsGeoBoundaryChange={onPlotsGeoBoundaryChange}
            zoom={!!Number(formik.values.location.lat) ? 18 : 6}
            geoBoundary={formik.values.geoBoundary?.coordinates[0]}
          />
        </IonContent>
      </IonModal>

      {/* qr code scanner modal */}
      <IonModal isOpen={showQRScannerModal}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>
              QR Scanner
            </IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={closeQRScanner}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
        <IonItem>
            <IonLabel position="stacked">IMEI</IonLabel>
            <IonInput
              id="enterendIMEI"
              name="enterendIMEI"
              value={enteredIMEI}
              placeholder="Enter IMEI"
              onIonChange={handleIMEIChange}
            ></IonInput>
          </IonItem>
          <IonItem>
          <Scanner onScan={(result) => {
            const readValue = result[0].rawValue;
            setEnteredIMEI(readValue);
          }} />
          </IonItem>
          <IonButton expand="full" onClick={e => validateIMEI(enteredIMEI)}>Submit</IonButton>
          <IonButton expand="full" color="danger" onClick={closeQRScanner}>Close</IonButton>
        </IonContent>
      </IonModal>
    </>
  );
};

export default CreatePlot;
