import React, { useState } from "react";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonBackButton,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonModal,
  IonButton,
  IonInput,
  IonLabel,
  IonItem,
  IonDatetime,
} from "@ionic/react";
import { addOutline } from "ionicons/icons";
import EventCard from "../components/EventCard";
import useNavigation from "../hooks/useNavigation";
import  { useEventStore } from "../store/useEventStore";
import {EventT} from "../store/types.store"


interface EventProps {}

const initialEventState: EventT = {
  id: 0,
  name: "",
  farmersCount: 0,
  date: new Date().toISOString().split('T')[0],
  farmers: [],
};

const Event: React.FC<EventProps> = () => {
  const { events, addEvent } = useEventStore.getState();
  const [showModal, setShowModal] = useState(false);
  const [newEvent, setNewEvent] = useState<EventT>(initialEventState);
  const navigateTo = useNavigation();

  const handleAddEvent = () => {
    setShowModal(true);
  };
  const handleModalContinue = () => {
    if (!newEvent.name || !newEvent.date) {
      alert("Please fill all the fields.");
      return;
    }

    const eventId = new Date().toISOString();
    const newEventWithId = {
      ...newEvent,
      id: eventId,
      farmers: [],
    };

    console.log(newEventWithId);

    addEvent(newEventWithId);

    setShowModal(false);
    setNewEvent(initialEventState);

    navigateTo("/tabs/event-farmer-form", { eventId: eventId });
  };
  const handleInputChange = (field: string, value: string) => {
    setNewEvent((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  

  return (
    <>
      <IonPage>
        {/* Header */}
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/" />
            </IonButtons>
            <IonTitle>Events</IonTitle>
          </IonToolbar>
        </IonHeader>

        {/* Content */}
        <IonContent className="ion-padding">
          {events &&
            events.map((event, index) => (
              <EventCard
                key={index}
                id={event.id}
                name={event.name}
                date={event.date}
                farmers={event.farmers}
                numberOfFarmers={event.farmersCount}
              />
            ))}

          {/* Floating Add Button */}
          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton color="primary" onClick={handleAddEvent}>
              <IonIcon icon={addOutline} />
            </IonFabButton>
          </IonFab>
        </IonContent>

        {/* Modal for Adding Event */}
        <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Add New Event</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            {/* Event Name */}
            <IonItem>
              <IonLabel position="stacked">Event Name</IonLabel>
              <IonInput
                placeholder="Enter event name"
                value={newEvent.name}
                onIonChange={(e) => handleInputChange("name", e.detail.value!)}
              />
            </IonItem>

            {/* Date */}
            <IonItem>
              <IonLabel position="stacked">Date</IonLabel>
              <IonDatetime
                placeholder="Select date"
                value={newEvent.date}
                onIonChange={(e) => handleInputChange("date", e.detail.value!)}
              />
            </IonItem>

            {/* Action Buttons */}
            <div className="ion-padding ion-text-center">
              <IonButton color="primary" onClick={handleModalContinue}>
                Continue
              </IonButton>
              <IonButton
                color="light"
                onClick={() => setShowModal(false)}
                className="ion-margin-start"
              >
                Cancel
              </IonButton>
            </div>
          </IonContent>
        </IonModal>
      </IonPage>
    </>
  );
};

export default Event;

