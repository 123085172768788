import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonChip,
  IonContent,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getPlotSensorData } from "../services/plot.service";
import TimeSeriesChart from "./TimeSeriesChart";
import { createFieldExtractor } from "../utilities/utils";

interface IProps {
  plot?: any;
  sinceHours?: string;
}

export function getChartData(data: any[], field: string) {
  return data.map((item: any, index: number) => {
    return {
      time: item.timestamp,
      value: item[field],
      anomalies: item.anomalies ? item.anomalies[field] : null,
    };
  });
}

const PlotSensorData: React.FC<IProps> = (props) => {
  const location = useLocation();
  const [plot, setPlot] = useState<any>();
  const [sinceHours, setSinceHours] = useState<number>(24);
  const [version, setVersion] = useState<number>();
  const [device, setDevice] = useState<any>();
  const [anomalousFields, setAnomalousFields] = useState<string[]>([]);
  const [sensorData, setSensorData] = useState<any[]>([]);
  const [nearbyPlots, setNearbyPlots] = useState<string[]>([]);
  const [selectedNearbyPlot, setSelectedNearbyPlot] = useState<string | null>();
  const [additionalData, setAdditionalData] = useState<any[]>([]);

  useEffect(() => {
    setPlot((location.state as any)?.plot ?? null);
    setSinceHours((location.state as any)?.sinceHours ?? 24);
  }, []);

  useEffect(() => {
    let mounted = true;
    if (plot && sinceHours) {
      getPlotSensorData(plot.plotId, sinceHours).then((sensorData) => {
        if (mounted) {
          setAnomalousFields(sensorData.anomalousFields);
          setSensorData(sensorData.data);
          setDevice(sensorData.device);
          setNearbyPlots(plot.nearestPlots || []);
          const latestData =
            sensorData.data && sensorData.data[sensorData.data.length - 1];
          setVersion(latestData?.Vp || latestData?.Vi);
        }
      });
    }
    return () => {
      mounted = false;
    };
  }, [plot, sinceHours]);

  function selectNearbyPlot(plotId: string) {
    if (plotId === selectedNearbyPlot) {
      setSelectedNearbyPlot(null);
      setAdditionalData([]);
    } else {
      setSelectedNearbyPlot(plotId);
      getPlotSensorData(plotId, sinceHours).then((sensorData) => {
        setAdditionalData(sensorData.data);
      });
    }
  }


  const extractNumericFields = createFieldExtractor("number");
  const fields = extractNumericFields(sensorData ?? []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Sensor Data</IonTitle>
        </IonToolbar>
      </IonHeader>
      {plot && (
        <IonContent>
          <IonList>
            <IonItem>
              <IonLabel position="stacked">Farm Id</IonLabel>
              <IonInput
                readonly
                id="farmId"
                name="farmId"
                value={plot.farmId}
              ></IonInput>
            </IonItem>

            <IonItem>
              <IonLabel position="stacked">Plot Id</IonLabel>
              <IonInput
                readonly
                id="plotId"
                name="plotId"
                value={plot.plotId}
              ></IonInput>
            </IonItem>

            <IonItem>
              <IonLabel position="stacked">Plot Name</IonLabel>
              <IonInput
                readonly
                id="name"
                name="name"
                value={plot.name}
              ></IonInput>
            </IonItem>

            <IonItem>
              <IonLabel position="stacked">Since Hours (1-720 hrs)</IonLabel>
              <IonInput
                id="sinceHours"
                name="sinceHours"
                type="number"
                inputmode="numeric"
                value={sinceHours}
                placeholder="Default is 24 hrs"
                debounce={1000}
                onIonChange={(e: any) => setSinceHours(e.target.value)}
              ></IonInput>
            </IonItem>

            <IonItem>
              <IonLabel position="stacked">Batch ID</IonLabel>
              <IonInput
                id="batchId"
                name="batchId"
                readonly
                value={device?.batchId}
                placeholder="Batch ID"
              ></IonInput>
            </IonItem>

            <IonItem>
              <IonLabel position="stacked">
                Firmware version{" "}
                {sensorData[sensorData.length - 1]?.Vp
                  ? "(Vp)"
                  : sensorData[sensorData.length - 1]?.Vi
                  ? "(Vi)"
                  : ""}
              </IonLabel>
              <IonInput
                id="version"
                name="version"
                type="number"
                readonly
                value={version}
                placeholder="Firmware version"
              ></IonInput>
            </IonItem>

            {anomalousFields.length > 0 && (
              <>
                <IonItem lines="none">
                  <IonLabel>Anomalous Fields</IonLabel>
                </IonItem>
                <IonItem lines="none">
                  <IonGrid>
                    <IonRow>
                      {anomalousFields.map((key: any, index: number) => (
                        <IonChip outline={true} color="primary" key={index}>
                          <IonLabel>{key}</IonLabel>
                        </IonChip>
                      ))}
                    </IonRow>
                  </IonGrid>
                </IonItem>
              </>
            )}

            {nearbyPlots.length > 0 && (
              <>
                <IonItem lines="none">
                  <IonLabel>Nearby Plots</IonLabel>
                </IonItem>
                <IonItem lines="none">
                  <IonGrid>
                    <IonRow>
                      {nearbyPlots.map((key: any, index: number) => (
                        <IonChip
                          outline={key === selectedNearbyPlot ? false : true}
                          color="primary"
                          key={index}
                          onClick={(e) => selectNearbyPlot(key)}
                        >
                          <IonLabel>{key}</IonLabel>
                        </IonChip>
                      ))}
                    </IonRow>
                  </IonGrid>
                </IonItem>
              </>
            )}

            {fields.map((field: string, index: number) => {
              return (
                <IonCard id={field} key={index}>
                  <IonCardHeader>
                    <IonCardSubtitle>{field}</IonCardSubtitle>
                  </IonCardHeader>
                  <TimeSeriesChart
                    format={"date"}
                    chartData={getChartData(sensorData, field)}
                    additionalData={getChartData(additionalData, field)}
                  />
                </IonCard>
              );
            })}
          </IonList>
        </IonContent>
      )}
    </IonPage>
  );
};

export default PlotSensorData;
